import type { PassSdkConfig } from '@canalplus/sdk-pass';
import { useContext } from 'react';
import { PassSdkContext } from '../../context';

/**
 * Hook to safely access `PassSdkContext`\
 * Throws an error if the context is not available
 */
export const usePassSdkContext = (): PassSdkConfig => {
  const context = useContext(PassSdkContext);

  if (context === null) {
    throw new Error('PassSdkContext is not defined');
  }

  return context;
};
