import type { SyntheticEvent } from 'react';
import type { MediaImageProps } from './MediaImage.types';

/**
 * Triggers the `animate-fade-in-media-image` when the given image's `onLoad` event fires:
 *
 * 1. Sets image `opacity` back to 1
 * 2. Fades in the image using the css fade-in animation
 */
export function fadeInOnLoad(onLoad: MediaImageProps['onLoad']) {
  return (event: SyntheticEvent<HTMLImageElement, Event>): void => {
    onLoad?.(event);
    event.currentTarget.classList.remove('opacity-0');
    event.currentTarget.classList.add('animate-fade-in-media-image');
  };
}

/**
 * Temporarily disables the fade-in animation and hide an image element
 * that is not yet fully loaded:
 *
 * 1. Sets image `opacity` to 0, preventing the default behavior of displaying it immediately.
 * 2. Removes css-only fade-in animation which is activated by default to enable fade-in on clients without JS.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/complete MDN docs - HTMLImageElement complete}
 */
export const handleRef = (imageElement: HTMLImageElement): void => {
  if (imageElement && !imageElement.complete) {
    imageElement.classList.add('opacity-0');
    imageElement.classList.remove('animate-fade-in-media-image');
  }
};
