import { useStore } from '@canalplus/one-navigation';
import { SafetyCodeContext } from '@canalplus/types-acm';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';
import {
  activeAdultSelector,
  adultBrowsingSelector,
} from '../../store/slices/adult-selectors';
import {
  immersiveDisplayNameSelector,
  immersiveDisplayTemplateSelector,
  immersiveParametersSelector,
  immersiveURLPageSelector,
  isImmersiveSelector,
} from '../../store/slices/immersive-selectors';
import { pageSelector } from '../../store/slices/page-selectors';
import LoadableAdultDisclaimerConnected from '../AdultDisclaimer/LoadableAdultDisclaimerConnected';
import Immersive from '../Immersive/Immersive';
import Player from '../PlayerFullScreen/Player';
import Template from '../Template/Template';
import { RoutingContextProvider } from './RoutingContext';

const ImmersiveModal = () => {
  const isImmersive = useSelector(isImmersiveSelector);
  const displayName = useSelector(immersiveDisplayNameSelector);
  const displayTemplate = useSelector(immersiveDisplayTemplateSelector);
  const immersiveParameters = useSelector(immersiveParametersSelector);
  const URLPage = useSelector(immersiveURLPageSelector);
  const isTvDevice = useIsTvDevice();

  // onFocusable should be called by the underlying template when it is loaded and first binder has focusable elements
  // we use that at page level to call focusDefault() and set focus
  const store = useStore();
  const handleFocusable = useCallback(() => {
    if (isTvDevice) {
      store.focusDefault();
    }
  }, [store, isTvDevice]);

  if (!isImmersive) {
    return null;
  }

  return (
    <Immersive>
      <RoutingContextProvider routingContext="immersive">
        <Template
          displayName={displayName}
          displayTemplate={displayTemplate}
          onFocusable={handleFocusable}
          parameters={immersiveParameters}
          URLPage={URLPage}
        />
      </RoutingContextProvider>
    </Immersive>
  );
};

function Page(): JSX.Element {
  const page = useSelector(pageSelector);
  const isTvDevice = useIsTvDevice();
  const adult = {
    active: useSelector(activeAdultSelector),
    adultBrowsing: useSelector(adultBrowsingSelector),
  };

  // if we are in adult context and parental code not yet validated we display the adult disclaimer
  const showAdultDisclaimer = page?.mainOnClick?.adult && !adult.adultBrowsing;

  // onFocusable should be called by the underlying template when it is loaded and first binder has focusable elements
  // we use that at page level to call focusDefault() and set focus
  const store = useStore();
  const handleFocusable = useCallback(() => {
    if (isTvDevice) {
      store.focusDefault();
    }
  }, [store, isTvDevice]);

  return (
    <>
      <ImmersiveModal />
      {!showAdultDisclaimer ? (
        <RoutingContextProvider routingContext="page">
          <Template
            animationEnded
            displayName={page?.mainOnClick?.displayName}
            displayTemplate={page?.mainOnClick?.displayTemplate}
            onFocusable={handleFocusable}
            perso={page?.mainOnClick?.perso}
            parameters={page?.mainOnClick?.parameters}
            URLPage={page?.mainOnClick?.URLPage}
          />
        </RoutingContextProvider>
      ) : (
        <LoadableAdultDisclaimerConnected
          safetyCodeContext={SafetyCodeContext.Parental}
        />
      )}

      <Player />
    </>
  );
}

export default Page;
