import { getStringMapFromNumberStringObject } from '../function/getStringMapFromNumberStringObject';
import isNumberStringObject from '../function/isNumberStringObject';
import { isStringMap } from '../function/isStringMap';
import type { NumberStringObject, StringMap } from '../types';
import { addQueryParam } from './addQueryParam';

/**
 * URL Helper
 * --------------------
 *
 * Add multiples **query parameter** in an url
 *
 * @param url The **url** to parse
 * @param queries The **queries** to be added
 * @return Return a new url with all QP added
 *
 * @category Url
 * @examples
 * addQueriesParam('https://url.com', [['query1', 'value1'],['query2', 'value2']])
 * // => 'http://url.com?query1=value1&query2=value2'
 * addQueriesParam('https://url.com', { query1: 'value1', query2: 'value2' })
 * // => 'http://url.com?query1=value1&query2=value2'
 */
export const addQueriesParam = (
  url: string,
  queries: StringMap | NumberStringObject
): string => {
  let queriesStringMap;

  if (isStringMap(queries)) {
    queriesStringMap = queries;
  } else if (isNumberStringObject(queries)) {
    queriesStringMap = getStringMapFromNumberStringObject(queries);
  }

  const urlWithQueries = (queriesStringMap || []).reduce(
    (accumulator, query) => {
      const [queryName, queryValue] = query;

      return queryValue
        ? addQueryParam(accumulator, queryName, queryValue)
        : accumulator;
    },
    url
  );

  return urlWithQueries;
};
