import { isClientSide } from './isClientSide';

/**
 * Window Helper
 * --------------------
 *
 * Based on `isClientSide()`, check for\
 * requestAnimationFrame capabilities from the browser
 *
 * @returns `true` if `requestAnimationFrame` are avalaible
 *
 * @category Window
 */
export const canUseRaf = (): boolean =>
  isClientSide() && Boolean(window.requestAnimationFrame);
