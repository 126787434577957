import type { PassUserData } from '../types/PassUserData';

declare global {
  interface Window {
    passJSON?: PassUserData & { passToken: string };
    waitForPassJSON?: (fn?: () => void) => void;
  }
}

let callbacks: (() => void)[] = [];

/** Add the waitForPassJSON mechanism to the window object */
export const initWaitForPassJSON = (): void => {
  window.waitForPassJSON = (callback?: () => void) => {
    if (!window.passJSON && typeof callback === 'function') {
      callbacks.push(callback);

      return;
    }

    if (window.passJSON) {
      callback?.();

      if (callbacks.length) {
        callbacks.forEach((cb) => cb());
        callbacks = [];
      }
    }
  };
};
