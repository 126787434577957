import {
  grabKey,
  releaseKey,
  type OneCoreKeyName,
} from '@canalplus/ifc-onecore';
import { getObjectKeys } from '@canalplus/mycanal-commons';
import {
  KEY_BACK,
  KEY_DOWN,
  KEY_ENTER,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_UP,
  Key,
  Store,
} from '@canalplus/one-navigation';
import { clearTimeout, setTimeout } from 'timers';
import { TV_NAVIGATION_TIMEOUT } from '../../constants/tv';
import {
  firstLevelPageListSelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../store/slices/displayMode-selectors';
import type { IState } from '../../store/types/State-type';
import type { AppHistory } from '../../typings/routing';
import { isFirstLevelPage } from '../application/application-helper';
import { BINDER_DEFAULT_SELECTOR } from '../binder/constants';
import { initializeStore, isPointerVisible } from '../binder/pointer';
import { markPerformance } from '../performance/markPerformance';
import { LAYER_PAGE } from './layers';
import { getBinderTemplate } from './util';

const r7KeyMap = {
  Up: KEY_UP,
  Down: KEY_DOWN,
  Right: KEY_RIGHT,
  Left: KEY_LEFT,
  Enter: KEY_ENTER,
} as const satisfies Partial<Record<OneCoreKeyName, Key>>;

let isBackKeyGrabbed = false;
let timeoutNavigationOnFocus: NodeJS.Timeout | null;
let hasMarked = false;

/**
 * Bind R7 keys to one navigation store
 * @param store One navigation store
 */
export async function bindR7Keys(store: Store): Promise<void> {
  getObjectKeys(r7KeyMap).forEach((r7Key) => {
    grabKey(r7Key, () => {
      store.handleKey(r7KeyMap[r7Key]);
    });
  });
}

/**
 * Switch the visible focus state
 * @param previous Previously selected element
 * @param next Next selected element
 */
function handleFocusVisibleState(
  previous?: HTMLElement,
  next?: HTMLElement
): void {
  if (previous) {
    if (previous.getAttribute('data-tv-focusmode') === 'attribute') {
      previous.removeAttribute('data-tv-focus');
    } else {
      previous.classList.remove('tv__focus');
    }
    previous?.blur();
  }
  if (next) {
    if (next.getAttribute('data-tv-focusmode') === 'attribute') {
      next.setAttribute('data-tv-focus', 'true');
    } else {
      next.classList.add('tv__focus');
    }
    // TODO: one-navigation maybe add next.focus() here to start migration to native browser focus ?
    next?.blur();
  }
}

/**
 * Grab / Release R7 back key depending on current binder
 * @param store One navigation store
 */
function handleGrabBackKey(store: Store, backCallback: () => void): void {
  if (!store.currentBinder) {
    return;
  }

  const binderIsHeader = getBinderTemplate(store.currentBinder) === 'header';
  const currentFocusedElement = store.getActiveLayer().current;
  const firstBinderElement = store.currentBinder.elements[0];

  const isHeaderHomeButtonFocused =
    binderIsHeader && currentFocusedElement === firstBinderElement;

  if (isBackKeyGrabbed && isHeaderHomeButtonFocused) {
    // If the focus is on Home inside the header, we pass the focus to oneCore to open the popin
    releaseKey('Back', backCallback);
    isBackKeyGrabbed = false;
  } else if (!isBackKeyGrabbed && !isHeaderHomeButtonFocused) {
    grabKey('Back', backCallback);
    isBackKeyGrabbed = true;
  }
}

/**
 * Triggers navigation if given element should be navigated on focus
 * @param element Element just focused
 */
function handleNavigateOnFocus(element: HTMLElement): void {
  // Don't navigate on focus if pointer is visible
  if (isPointerVisible()) {
    return;
  }
  if (element.getAttribute('data-navigateonfocus') === 'true') {
    // Need a timeout to have good performance when quickly navigate on focus
    timeoutNavigationOnFocus = setTimeout(() => {
      element.click();
    }, TV_NAVIGATION_TIMEOUT);
  }
}

/**
 * Creates the handler for focus change event
 * @param store One navigation store
 * @returns Handler for focus change events
 */
function onFocusChangeFactory(
  store: Store,
  state: IState,
  backCallback: () => void
) {
  return (previous?: HTMLElement, next?: HTMLElement): void => {
    if (!previous && !hasMarked) {
      markPerformance('focus', state);
      hasMarked = true;
    }

    handleFocusVisibleState(previous, next);

    // Need always clear timeoutNavigationOnFocus when change focus
    // to avoid dispatch click not corresponding of the current focus element
    if (timeoutNavigationOnFocus) {
      clearTimeout(timeoutNavigationOnFocus);
      timeoutNavigationOnFocus = null;
    }

    if (next) {
      handleNavigateOnFocus(next);
    }

    handleGrabBackKey(store, backCallback);
  };
}

/**
 * Handler for enter key press
 * @param element Element currently focused
 */
function onEnter(element?: HTMLElement): void {
  if (element) {
    element.click();
  }
}

/**
 * Returns an handler for back key press
 * @param store One navigation store
 * @returns Back handler
 */
function onBackFactory(
  store: Store,
  history: AppHistory,
  reduxStore: Redux.CustomStore,
  backCallback: () => void
) {
  return (): void => {
    const state = reduxStore.getState();

    if (store.activeLayer !== LAYER_PAGE) {
      return;
    }

    const firstLevelPageList = firstLevelPageListSelector(state);
    const offerLocation = offerLocationSelector(state);
    const isTvDevice = displayTVModeSelector(state);
    const binderIsHeader = getBinderTemplate(store.currentBinder) === 'header';
    const currentFocusedElement = store.getActiveLayer().current;
    const firstBinderElement = store.currentBinder?.elements[0];

    // Need use directly 'history.location.pathname' (instead redux value pathname using isFirstLevelPageSelector)
    // to avoid multiple history go back when user tap multiple times the touch back
    // Because the update of redux value take some time and the check would not be on the good updated value
    // fix #9362
    if (
      !(
        isTvDevice &&
        isFirstLevelPage({
          firstLevelPageList,
          offerLocation,
          path: history.location.pathname,
        })
      )
    ) {
      // When immersive or non-1st-level page is opened, we call make a history.back()
      history.go(-1);
    } else if (binderIsHeader && currentFocusedElement !== firstBinderElement) {
      // if the focus is on the header but not on Home button, go to Home
      store.currentBinder?.focusByIndex(0);
      store.handleKey(KEY_ENTER);
    } else {
      // Otherwise we reset the focus to the default focused element
      store.getActiveLayer().focus(undefined);
      store.focusDefault();
      if (getBinderTemplate(store.currentBinder) !== 'header') {
        // if the default focused element is not the header, we pass the focus to oneCore
        releaseKey('Back', backCallback);
        isBackKeyGrabbed = false;
      }
    }
  };
}

/**
 * Initialize one navigation store for the app
 *
 * @todo one-navigation _history is currently unused but will be as soon as we implement
 * second-level page navigation
 *
 * @param history - History API
 * @returns One navigation store
 */
export function initOneNavigationStore(
  history: AppHistory,
  reduxStore: Redux.CustomStore
): Store {
  const store = new Store({
    onEnter,
    selector: BINDER_DEFAULT_SELECTOR,
    layerMode: 'stack',
  });

  const backCallback = () => store.handleKey(KEY_BACK);

  store.setOnFocusChange(
    onFocusChangeFactory(store, reduxStore.getState(), backCallback)
  );

  store
    .getLayer(LAYER_PAGE)
    .addListener(
      KEY_BACK,
      onBackFactory(store, history, reduxStore, backCallback)
    );

  initializeStore(() => store);

  return store;
}
