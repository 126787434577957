import loadable from '@loadable/component';
import Spinner from '../Spinner/Spinner';

const LoadableModal = loadable(
  () =>
    import(
      '@canalplus/mycanal-sharedcomponent/dist/components/Modal/Modal'
    ).then((module) => module.Modal),
  {
    fallback: <Spinner />,
  }
);

export default LoadableModal;
