import type {
  ComponentPropsWithoutRef,
  ElementType,
  HTMLAttributes,
} from 'react';
import type React from 'react';

import type { XOR } from 'ts-essentials';
import type {
  DiveBackgroundVariant,
  DiveProps,
} from '../../../types/Dive.types';

export enum ButtonLogoAsVariant {
  'Div' = 'div',
  'Button' = 'button',
}

type ButtonLogoAsDiv = {
  /**
   * The HTML element to render the button as.
   * @default 'button'
   */
  as: `${ButtonLogoAsVariant.Div}`;
} & HTMLAttributes<HTMLDivElement>;

type ButtonLogoAsButton = {
  /**
   * The HTML element to render the button as.
   * @default 'button'
   */
  as?: `${ButtonLogoAsVariant.Button}`;
} & HTMLAttributes<HTMLButtonElement>;

export type ButtonLogoProps<T extends ElementType> = {
  /**
   * The logo element to display inside the button.
   *
   * @example
   * <ButtonLogo logo={<img src="img.jpg" aria-label="lorem" />} />
   * <ButtonLogo logo={<MediaImage src="img.jpg" aria-label="lorem" />} />
   * <ButtonLogo logo={<ChannelCanalBoxOfficeSvg />} />
   */
  logo: React.ReactElement;
  /** The component's background color variant */
  variant?: `${DiveBackgroundVariant}`;
} & ComponentPropsWithoutRef<T> &
  XOR<ButtonLogoAsDiv, ButtonLogoAsButton> &
  DiveProps;
