import { ResizeViewportMapping } from '@canalplus/mycanal-commons';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { getDocumentHeaderMetasType } from '../../helpers/metaTags/metaTags-helper';
import {
  deleteQueryString,
  getProductionHostname,
  getUrlWithoutDefaultLang,
} from '../../helpers/url/url-helper';
import {
  applicationResizeSelector,
  fullLocaleSelector,
  hostnameSelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { immersiveOrPagePathnameSelector } from '../../store/slices/immersive-selectors';

/**
 * Init react-helmet with default values from app config
 */
export function MetaTagsDefault() {
  const urlPage = useSelector(immersiveOrPagePathnameSelector);
  const offerLocation = useSelector(offerLocationSelector);
  const currentUrl = useSelector(hostnameSelector);
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const documentHeader = getDocumentHeaderMetasType(offerLocation);
  const locale = useSelector(fullLocaleSelector);

  // viewport for data-viewport html attribute
  const dataViewport = resizeMode && ResizeViewportMapping[resizeMode];

  const canonicalUrl = `https://${getProductionHostname(currentUrl)}${getUrlWithoutDefaultLang(
    deleteQueryString(urlPage)
  )}`;

  // link meta tag
  const link = [
    { rel: 'canonical', href: canonicalUrl },
    ...(documentHeader?.app.head.link ?? []),
  ];

  const meta = useMemo(
    () => [
      ...(documentHeader?.app.head.meta ?? []).map((meta) => {
        // Update og:locale meta tag according to the current locale (full locale)
        if (meta.property === 'og:locale') {
          return { ...meta, content: locale };
        }
        return meta;
      }),
      { name: 'description', content: documentHeader?.app.description },
    ],
    [documentHeader, locale]
  );

  return (
    <Helmet
      {...documentHeader?.app.head}
      htmlAttributes={dataViewport ? { 'data-viewport': dataViewport } : {}}
      meta={meta}
      link={link}
      title={documentHeader?.app.title}
    />
  );
}
