import type { VirtualKeyboardState, Action } from './types';
import { ActionType } from './types';

export const reducer = (
  state: VirtualKeyboardState,
  action: Action
): VirtualKeyboardState => {
  switch (action.type) {
    case ActionType.UPDATE_MODE:
      return {
        ...state,
        mode: action.mode,
      };

    case ActionType.ADD_CHAR:
      return {
        ...state,
        inputValue: action.inputValue,
        cursorIndex: state.cursorIndex + 1,
      };

    case ActionType.DELETE_CHAR:
      if (state.cursorIndex) {
        return {
          ...state,
          inputValue: action.inputValue,
          cursorIndex: state.cursorIndex - 1,
        };
      }

      return state;

    case ActionType.SET_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.inputValue,
        cursorIndex: action.inputValue.length,
      };

    case ActionType.INCREMENT:
      if (state.cursorIndex + 1 <= state.inputValue.length) {
        return {
          ...state,
          cursorIndex: state.cursorIndex + 1,
        };
      }

      return state;

    case ActionType.DECREMENT:
      if (state.cursorIndex) {
        return {
          ...state,
          cursorIndex: state.cursorIndex - 1,
        };
      }

      return state;

    default:
      return state;
  }
};
