import type { StringMap } from '../types';
import { isStringCouple } from './isStringCouple';

/**
 * Function Helper
 * --------------------
 *
 * **StringMap** type guard.
 *
 * @param couple The **Map** to be parsed
 *
 * @category Function
 */
export const isStringMap = (token: any): token is StringMap =>
  Array.isArray(token) && token.every(isStringCouple);
