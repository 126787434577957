import { TimeoutError } from '@canalplus/mycanal-fetch';
import { isAcmApiError } from './isAcmApiError';

/**
 * Get status code from error
 *
 * - return **error.statusCode** for ACM api error
 * - return **0** for timeout error
 * - return **-1** for internal SDK ACM error
 */
export const getErrorStatusCode = (error: unknown): number => {
  if (isAcmApiError(error)) {
    return error.statusCode;
  }

  if (error instanceof TimeoutError) {
    return 0;
  }

  return -1;
};
