import { useEffect } from 'react';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { newrelicSendGlobalInfo } from '../../../helpers/newrelic/newrelic-helper';
import { getFeatureToggleNewrelic } from '../../../store/slices/application-selectors';

/**
 * Send Newrelic global info\
 * Used on mount only.
 */
export const useSendNewrelicGlobalInfo = (): void => {
  const isNewrelicEnabled = useInvariantSelector(getFeatureToggleNewrelic);

  useEffect(() => {
    (async function performSendNewrelicGlobalInfo() {
      if (isNewrelicEnabled && $_BUILD_APP_VERSION) {
        await newrelicSendGlobalInfo($_BUILD_APP_VERSION);
      }
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
