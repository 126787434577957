import {
  DIMENSIONS,
  PROFILE,
  type ResizeMode,
} from '@canalplus/mycanal-commons';
import { ProfileAvatar } from '@canalplus/mycanal-sharedcomponent';
import { Binder } from '@canalplus/one-navigation';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import classNames from 'classnames';
import { useContext } from 'react';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { LAYER_SETTINGS_MANAGE_PROFILES } from '../../../helpers/oneNavigation/layers';
import { MIDDLEWARE_PROFILES_LIST } from '../../../helpers/oneNavigation/middleware';
import I18n from '../../../lang';
import { imageLoadingSelector } from '../../../store/slices/application-selectors';
import styles from './ProfilesListTV.css';

/**
 * ProfilesList
 *
 * Show lists of profiles on TV device.
 *
 * @returns {JSX.Element}
 */

export type ProfilesListTVProps = {
  handleClick: (profile: ApiV2Profile) => void;
  profilesList: ApiV2Profile[];
  resizeMode?: ResizeMode;
};

function ProfilesListTV({
  handleClick,
  profilesList,
  resizeMode,
}: ProfilesListTVProps): JSX.Element {
  const { t } = useContext(I18n.context);
  const imageLoading = useInvariantSelector(imageLoadingSelector);

  const profileAvatarDimensions = DIMENSIONS.PROFILE_AVATAR.extraLarge;

  return (
    <div className={styles.profilesListTV}>
      <Binder
        layer={LAYER_SETTINGS_MANAGE_PROFILES}
        middleware={MIDDLEWARE_PROFILES_LIST}
      >
        <h2 className={styles.profilesListTV__title}>
          {t('ProfileManager.edit')}
        </h2>
        <ul className={classNames(styles.profilesListTV__list)}>
          {profilesList.map(
            (profile) =>
              profile.type === PROFILE && (
                <li
                  className={styles.profilesListTV__item}
                  key={profile.profileId}
                >
                  <button
                    className={classNames(
                      styles.profilesListTV__avatar,
                      'globalProfilesListTV'
                    )}
                    type="button"
                    onClick={() => handleClick(profile)}
                  >
                    <ProfileAvatar
                      avatar={profile.URLImage || ''}
                      altImage={profile.displayName || ''}
                      customClass={styles.profilesListTV__profileAvatar}
                      dimensions={profileAvatarDimensions}
                      isEditable
                      isKidsProfile={profile.isKidsProfile}
                      isTvDevice={true}
                      resizeMode={resizeMode}
                      loading={imageLoading}
                    />
                  </button>
                  <div className={styles.profilesListTV__nameWrapper}>
                    <span className={styles.profilesListTV__name}>
                      {profile.displayName}
                    </span>
                  </div>
                </li>
              )
          )}
        </ul>
      </Binder>
    </div>
  );
}

export default ProfilesListTV;
