import { useEffect, useReducer } from 'react';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { loadTrackingScripts } from '../../../helpers/tracking/tracking-helper';
import {
  appKeySelector,
  getFeatureToggleDidomi,
  offerLocationSelector,
  trackingLibraryUrlSelector,
} from '../../../store/slices/application-selectors';
import { displayWebviewModeSelector } from '../../../store/slices/displayMode-selectors';

/**
 * Load tracking scripts of the application
 *
 * ⚠️ This action must be placed before renderSource action\
 * because we need to load tracking script before client to have sendPageView
 */
const useLoadTrackingScriptsWeb = (): boolean => {
  const [isLoaded, setIsLoaded] = useReducer(() => true, false);
  const publicConfig = getPublicConfig();

  const offerLocation = useInvariantSelector(offerLocationSelector);
  const isTvDevice = useIsTvDevice();
  const isWebview = useInvariantSelector(displayWebviewModeSelector);
  const trackingLibraryUrl = useInvariantSelector(trackingLibraryUrlSelector);
  const isFeatDidomi = useInvariantSelector(getFeatureToggleDidomi);
  const appKey = useInvariantSelector(appKeySelector);
  // Check if Didomi is active in config or feature toggle to show Legacy or Didomi consent banner
  const isDidomi = publicConfig.DIDOMI?.ACTIVE || isFeatDidomi;

  useEffect(() => {
    loadTrackingScripts(
      appKey,
      offerLocation,
      isTvDevice || isWebview || isDidomi,
      trackingLibraryUrl,
      isTvDevice
    );
    setIsLoaded();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return isLoaded;
};

const useLoadTrackingScriptsTv = (): boolean => false;

export const useLoadTrackingScripts = $_BUILD_RENDERMODE_CSR
  ? useLoadTrackingScriptsTv
  : useLoadTrackingScriptsWeb;
