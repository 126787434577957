/**
 * Tricks to avoid a jump of the page when the user opens or closes the immersive page.
 * Depending on the user's browser settings, there may be a scrollbar.
 * In that case, we apply a padding-right of the size of the scrollbar
 * on the body element and the myCANAL header element.
 * If the user has not scrollbar on his browser, no padding is applied.
 */
// TODO: remove this crappy code :scream: (find a solution like blockAppScroll)
export const applyBodyScrollbarWidth = (width: number): void => {
  if (width === 0) {
    document.body.style.removeProperty('padding-right');
  } else {
    document.body.style.paddingRight = `${width}px`;
  }
};
