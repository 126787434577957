import { AppMenuSearchSvg, ButtonIcon } from '@canalplus/dive';
import { DATA_ATTR_HEADER_SEARCH_TRIGGER } from '@canalplus/mycanal-sharedcomponent';
import { NavigationInfoItem } from '@dce-front/hodor-types/modules/account_profile/definitions';
import { cloneElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActionCreators } from '../../../helpers/hooks/useActionCreators';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useIsTvDevice } from '../../../helpers/hooks/useIsTvDevice';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import I18n from '../../../lang';
import { FetchRequestTypes } from '../../../services/types';
import { setSearchPageParameters } from '../../../store/slices/application';
import { setSearchActive } from '../../../store/slices/search';
import { isSearchActiveSelector } from '../../../store/slices/search-selectors';
import { ApiV2SearchPageWithSearchKey } from '../../../templates/SearchStandalone/data/formatter';
import Linker from '../../Linker/Linker';
import { SKIP_LINK_ID } from '../../SkipLink/constants';

type HeaderSearchButtonOneDiscoveryProps = {
  isActive?: boolean;
  data?: NavigationInfoItem;
};

export function HeaderSearchButtonOneDiscovery({
  isActive = false,
  data,
}: HeaderSearchButtonOneDiscoveryProps): JSX.Element {
  const { onClick } = data || {};
  const dispatch = useAppDispatch();
  const isSearchActive = useSelector(isSearchActiveSelector);

  // @todo Delete this type when hodor exposes 'searchkey' in the pageParameters object in ApiV2SearchPage
  const [{ data: dataFetch }] = useQueryTemplate<ApiV2SearchPageWithSearchKey>(
    onClick?.URLPage,
    { template: FetchRequestTypes.SearchStandalone },
    {
      enabled: isSearchActive,
    }
  );

  useEffect(() => {
    if (dataFetch?.pageParameters) {
      dispatch(setSearchPageParameters(dataFetch?.pageParameters));
    }
  }, [dataFetch?.pageParameters, dispatch]);

  const { t } = I18n.useTranslation();
  const isTvDevice = useIsTvDevice();
  const { openSearch } = useActionCreators({ openSearch: setSearchActive });

  const searchComponent = (
    <ButtonIcon
      id={SKIP_LINK_ID.appHeaderSearch}
      aria-label={t('Search.activateSearchTriggerLabel')}
      icon={<AppMenuSearchSvg />}
      isSelected={isActive}
      variant="header"
      {...(!isTvDevice && {
        onClick: () => openSearch(),
        [DATA_ATTR_HEADER_SEARCH_TRIGGER]: true, // Do not delete: this is used to measure the DOM when header search initiates
      })}
    />
  );

  return isTvDevice ? (
    <Linker
      data={onClick ? { mainOnClick: onClick } : undefined}
      renderWrapper={(linkerSettings) =>
        cloneElement(searchComponent, { onClick: linkerSettings.onClick })
      }
    />
  ) : (
    searchComponent
  );
}
