import { isSomeEnum } from './isSomeEnum';

/**
 * Function Helper
 * --------------------
 *
 * Used to cast a value to an enum type, with an optional fallback value.
 * Returns the typed value if is part of enum, otherwise the **fallbackValue** that defaults to undefined.
 *
 * @param someEnum The **enum** to cast the value to
 * @param fallbackValue An optional fallback to return if the provided **value** is not part of the **enum**
 * @param value The **value** to cast
 *
 * @category Function
 */
export const castToEnum = <T extends Record<string, unknown>>(
  someEnum: T,
  fallbackValue?: T[keyof T]
) => {
  const isEnum = isSomeEnum(someEnum);
  return (value: unknown): T[keyof T] | undefined =>
    isEnum(value) ? value : fallbackValue;
};
