import { isSomeEnum } from '../function/isSomeEnum';

export enum Ratio {
  Ratio23 = '23',
  Ratio236 = '236',
  Ratio34 = '34',
  Ratio43 = '43',
  Ratio47 = '47',
  Ratio166 = '166',
  Ratio169 = '169',
  Square = 'square',
}

export const DEFAULT_RATIO = Ratio.Ratio169;

export const isRatio = isSomeEnum(Ratio);

export const getRatio = ({
  defaultValue,
  imageRatio,
}: {
  imageRatio?: Ratio | string;
  defaultValue?: Ratio;
}): Ratio => {
  return isRatio(imageRatio) ? imageRatio : defaultValue || DEFAULT_RATIO;
};
