import { isClientSide } from './isClientSide';

/**
 * Window Helper
 * --------------------
 *
 * Based on `isClientSide()`, check for\
 * event listener capabilities from the browser
 *
 * @returns `true` if event listeners are avalaible
 *
 * @category Window
 */
export const canUseEventListeners = (): boolean =>
  isClientSide() && Boolean(window.addEventListener);
