export type DiveState = {
  /**
   * A boolean indicating if the app is rendered on a TV device
   */
  isTv?: boolean;
  /**
   * The type of `DiveDevice` the app is rendered on (e.g.: `"web"`, `"tv"`)
   */
  device?: DiveDevice;
};

export type DiveContextProviderProps = {
  children: React.ReactElement | React.ReactNode;
} & DiveState;

export type DiveSvgProps = React.SVGProps<SVGSVGElement> & {
  title?: string;
  description?: string;
} & DiveProps;

export type DiveSVG = React.FC<DiveSvgProps>;

export type DiveProps = {
  /**
   * Test identifier of the component's top level wrapper
   */
  'data-testid'?: string;
  /**
   * Additional styles applied on the component wrapper.
   */
  className?: string;
};

export enum DiveTheme {
  MyCanalDark = 'mycanal dark',
  MyCanalLight = 'mycanal light',
  BusinessDark = 'business dark',
  BusinessLight = 'business light',
  VODDark = 'vod dark',
  VODLight = 'vod light',
  VODaltDark = 'vodAlt dark',
  VODaltLight = 'vodAlt light',
  TelecomItalia = 'telecomitalia',
}

export enum DiveFontFamily {
  Canal = 'canal',
  Hind = 'hind',
}

export enum DiveDevice {
  Web = 'web',
  Tv = 'tv',
}

export enum DivePseudoClass {
  Default = 'default',
  Hover = 'hover',
  Active = 'active',
  Disabled = 'disabled',
  Focus = 'focus',
}

export enum DiveIconPosition {
  Left = 'left',
  Right = 'right',
}

/** A component's background color variant */
export enum DiveBackgroundVariant {
  Solid = 'solid',
  Ghost = 'ghost',
}

export enum DiveBreakpoint {
  Xxs = 375,
  Xs = 420,
  Sm = 700,
  Md = 960,
  Lg = 1285,
  Xl = 1600,
  Xxl = 1920,
}

export type DiveViewport =
  | 'tv1080p'
  | 'tv720p'
  | 'XLarge'
  | 'large'
  | 'medium'
  | 'small'
  | 'XSmall';

export type DiveDeviceMediaImage = 'default' | 'tablet' | 'laptop' | 'mobile';

export type DiveViewportsWithDevice = {
  viewport: DiveViewport;
  device?: DiveDeviceMediaImage;
  mediaQuery?: string;
}[];
