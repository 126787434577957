import type { Type } from '@canalplus/mycanal-commons';

export type VirtualKeyboardState = {
  cursorIndex: number;
  inputValue: string[];
  mode: Mode;
};

export enum ActionType {
  ADD_CHAR = 'ADD_CHAR',
  DECREMENT = 'DECREMENT',
  DELETE_CHAR = 'DELETE_CHAR',
  INCREMENT = 'INCREMENT',
  RESET = 'RESET',
  SET_INPUT_VALUE = 'SET_INPUT_VALUE',
  UPDATE_MODE = 'UPDATE_MODE',
}

export type ActionAddCharacter = {
  type: ActionType.ADD_CHAR;
  inputValue: string[];
};

export type ActionDecrement = { type: ActionType.DECREMENT };

export type ActionDeleteCharacter = {
  type: ActionType.DELETE_CHAR;
  inputValue: string[];
};

export type ActionIncrement = { type: ActionType.INCREMENT };

export type ActionUpdateMode = { type: ActionType.UPDATE_MODE; mode: Mode };

export type ActionReset = { type: ActionType.RESET; mode: Mode };

export type ActionSetInputValue = {
  type: ActionType.SET_INPUT_VALUE;
  inputValue: string[];
};

export type Action =
  | ActionAddCharacter
  | ActionDecrement
  | ActionDeleteCharacter
  | ActionIncrement
  | ActionUpdateMode
  | ActionSetInputValue;

export enum Mode {
  LOWERCASE = 'lowercase',
  UPPERCASE = 'uppercase',
  SPECIALS = 'specials',
}

export type Key = {
  id: string;
  sign: string;
  special?: string;
  type?: Type;
};
