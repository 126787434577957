import { isClientSide } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import classNames from 'classnames/bind';
import { memo, useCallback, useEffect, useRef } from 'react';
import IconArrow from '../../assets/svg/arrow.svg';
import IconCloseV5 from '../../assets/svg/closeV5.svg';
import { useAppHistory, useAppLocation } from '../../helpers/hooks/reactRouter';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';
import I18n from '../../lang';
import styles from './ImmersiveNav.css';

const cx = classNames.bind(styles);

export type ImmersiveNavProps = {
  close: () => void;
  hasFocus: boolean;
  isModalOpen: boolean;
  displayTemplate?: string;
};

/**
 * ImmersiveNav (That includes the close button and the previous button)
 * Usually only used on Desktop (Web) and never on TV
 *
 * @param close                           close function of parent component (is not closeAction)
 * @param hasFocus                        whether other element is opened in immersive (e.g modal, player)
 * @param isModalOpen                     whether the modal is opened in immersive
 * @param removeLastHistoryLinkAction     remove last path of immersive history array
 * @param displayTemplate                 immersive display template
 */
function ImmersiveNav({
  close,
  hasFocus,
  isModalOpen,
  displayTemplate,
}: ImmersiveNavProps): JSX.Element {
  const { t } = I18n.useTranslation();
  const isTvDevice = useIsTvDevice();

  const isDetailLight = displayTemplate === Template.DetailLight;

  const history = useAppHistory();
  const location = useAppLocation();

  const navRef = useRef<HTMLDivElement>(null);

  if (isClientSide()) {
    const closeButton = document.getElementById('closeButton');
    if (closeButton)
      closeButton.addEventListener('click', (e) => e.preventDefault());
  }

  const handleClickClose = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      close();
    },
    [close]
  );

  useEffect(() => {
    if (!isTvDevice && navRef.current) {
      navRef.current.focus();
    }
  }, [isTvDevice]);

  const handleClickPrevHistory = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      history.goBack();
    },
    [history]
  );

  return (
    <nav
      className={cx('immersiveNav', {
        'immersiveNav--isDetailLight': isDetailLight,
      })}
      onClick={handleClickClose}
      ref={navRef}
      tabIndex={-1}
    >
      {location.state?.immersive?.stackSize &&
        location.state.immersive.stackSize > 1 && (
          <button
            className={cx('immersiveNav__prev')}
            onClick={handleClickPrevHistory}
            type="button"
            title={t('HorizontalPaging.previous')}
            aria-label={t('Icon.back')}
          >
            <IconArrow title={t('HorizontalPaging.previous')} />
          </button>
        )}
      {hasFocus && (
        <button
          className={cx('immersiveNav__close', {
            'immersiveNav__close--disabled': isModalOpen,
          })}
          onClick={handleClickClose}
          data-testid="imersive-close"
          type="button"
          aria-label={t('Icon.close')}
        >
          <IconCloseV5 title={t('Icon.close')} />
        </button>
      )}
    </nav>
  );
}

export default memo(ImmersiveNav);
