import type { StringMap } from '../types';

/**
 * URL Helper
 * --------------------
 *
 * Compute a queries `StringMap` from a valid `URLSearchParams` init.
 *
 * @param params The init to parse
 * @return Return a well formatted `StringMap`
 *
 * @category Url
 *
 * @example
 * const search = '?query1=value1&query2=value2';
 * getStringMapQueriesFromHistorySearch(search)
 * // => [['query1', 'value1'],['query2', 'value2']]
 */
export const getStringMapFromParams = (
  params: ConstructorParameters<typeof URLSearchParams>[0]
): StringMap => Array.from(new URLSearchParams(params).entries());
