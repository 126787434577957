import { isAcmApiError } from './isAcmApiError';

/**
 * Get message from error
 *
 * - return **error.errorDescription** for ACM api error
 * - return **error.message** for internal SDK ACM error
 * - return **Unknown error** otherwise
 */
export const getErrorMessage = (error: unknown): string => {
  if (isAcmApiError(error)) {
    return error.errorDescription;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
};
