import { isSomeEnum } from '../function/isSomeEnum';

export enum PlaysetQuality {
  Sd = 'SD',
  Hd = 'HD',
  Hdr = 'HDR',
  Uhd = 'UHD',
  UhdHdr = 'UHD-HDR',
  UhdDbv = 'UHD-DBV',
  Mono = 'MONO',
  Stereo = 'STEREO',
  Dolby_5_1 = 'DOLBY_5.1',
  Dolby_5_1WithSpace = 'DOLBY 5.1',
  DolbyAtmos = 'DOLBY_ATMOS',
}

export const isPlaysetQuality = isSomeEnum(PlaysetQuality);
