import { trackSendPageView } from '@canalplus/ifc-onecore';
import { addQueryParam } from '@canalplus/mycanal-commons';
import { Template } from '@canalplus/sdk-hodor';
import { LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { useSelector, useStore } from 'react-redux';
import { StrateMode } from '../../../constants/strates';
import { TemplateTypes } from '../../../constants/templateTypes';
import { ThemeColor } from '../../../constants/themeColor';
import { Queries } from '../../../constants/url';
import { getPublicConfig } from '../../../helpers/config/config-helper';
import { useAppHistory } from '../../../helpers/hooks/reactRouter';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useAuthFromExternalSiteWithIDPToken } from '../../../helpers/hooks/useAuthFromExternalSiteWithIDPToken';
import { useSigninRedirect } from '../../../helpers/pass/useSigninRedirect';
import {
  clientSideOneShopRedirection,
  clientSideSelectRegionRedirection,
} from '../../../helpers/user/user-helper';
import {
  offerLocationSelector,
  platformSelector,
} from '../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../store/slices/displayMode-selectors';
import { immersiveSelector } from '../../../store/slices/immersive-selectors';
import { pageSelector } from '../../../store/slices/page-selectors';
import { launchPlayerFullScreen } from '../../../store/slices/player-thunk';
import {
  idpTokenSelector,
  isKidsProfileSelector,
} from '../../../store/slices/user-selectors';
import { IState } from '../../../store/types/State-type';
import type { LocationState } from '../../../typings/routing';
import { getLocationState } from '../helpers/getLocationState';
import { LinkerProps, LinkerSettings } from '../types';
import { getOnClickGenerated, redirectRouter } from './helpers/useLinkerHelper';

export type UseLinker = {
  /**
   * Returns settings for the Linker component. Useful when trying to get `onClick`, `href` and `target` values for your action element
   * @param props LinkerProps
   * @returns LinkerSettings The linker settings
   * @example
      const linkerSettings = getLinkerSettings({ data: { onClick: button.onClick }});
      if(linkerSettings.href) {
        return <a className={styles.defaultTemplate_button} onClick={linkerSettings.onClick} href={linkerSettings.href} target={linkerSettings.target}>
          My Link
        </a>
      }else {
        return <button type="button" className={styles.defaultTemplate_button} onClick={linkerSettings.onClick}>
          My Link
        </button>
      }
   */
  getLinkerSettings: (props: LinkerProps) => LinkerSettings;
};

/**
 * Give a method (getLinkerSettings) to generate LinkerSettings.
 * Useful when trying to get `onClick`, `href` and `target` values for your action element
 * @example
    const { getLinkerSettings } = useLinker();
 * @returns UseLinker object { getLinkerSettings }
 */
const useLinker = (): UseLinker => {
  const store = useStore<IState>();
  const history = useAppHistory();
  const dispatch = useAppDispatch();

  const idpToken = useSelector(idpTokenSelector);
  const { authenticate } = useAuthFromExternalSiteWithIDPToken(idpToken);
  const handleConnectClick = useSigninRedirect();

  const getLinkerSettings = useCallback(
    (linkerProps: LinkerProps): LinkerSettings => {
      const publicConfig = getPublicConfig();
      const {
        data,
        objKey = 'onClick',
        target = '_blank',
        replace,
      } = linkerProps;
      const { mainOnClick, subOnClick, context, contentID } = data || {};
      const {
        displayTemplate,
        path,
        URLWebsite,
        displayMode,
        target: onClickTarget,
      } = subOnClick || mainOnClick || {};

      // Values from redux store
      const reduxState = store.getState();
      const immersiveState = immersiveSelector(reduxState);
      const isTvDevice = displayTVModeSelector(reduxState);
      const offerLocation = offerLocationSelector(reduxState);
      const pageState = pageSelector(reduxState);
      const platform = platformSelector(reduxState);
      const isKids = isKidsProfileSelector(reduxState);

      const linkTarget = isTvDevice ? '_self' : onClickTarget || target;
      const query = path?.split('?')[1] || '';
      const brand = publicConfig.api.hodor.defaultAppKey;

      const onOneShopClick = (contentId?: string) =>
        clientSideOneShopRedirection(contentId);
      const onSelectRegionClick = (contentId?: string) =>
        clientSideSelectRegionRedirection(contentId);
      const handleClick = getOnClickGenerated(
        linkerProps,
        dispatch,
        isTvDevice,
        offerLocation,
        platform
      );

      if (displayTemplate === Template.ExternalSiteWithIDPToken && URLWebsite) {
        return {
          onClick: () => {
            authenticate(URLWebsite);
          },
        };
      }

      if (displayTemplate === Template.ExternalSite && URLWebsite) {
        return { href: URLWebsite, target: linkTarget, onClick: handleClick };
      }

      if (displayTemplate === Template.UpdateRights) {
        const refreshRightRef = addQueryParam(
          window.location.href,
          Queries.RefreshRight,
          'true'
        );
        return { href: refreshRightRef, target: '_self', onClick: handleClick };
      }

      if (displayTemplate === Template.DownloadManager) {
        return { onClick: handleClick };
      }

      if (displayTemplate === Template.Authentication) {
        return { onClick: () => handleConnectClick() };
      }

      if (displayTemplate === Template.ChangeGeoZone) {
        return {
          onClick: () => {
            onSelectRegionClick(contentID);
          },
        };
      }

      if (displayTemplate === TemplateTypes.LAUNCH_ONE_SHOP) {
        return {
          onClick: () => {
            onOneShopClick(contentID);
          },
        };
      }

      if (displayTemplate === Template.Player && objKey !== StrateMode.LiveTv) {
        return { onClick: handleClick };
      }

      const { location } = history;

      const state = getLocationState({
        context,
        immersiveState,
        location,
        pageState,
        replace,
        ...(mainOnClick ? { mainOnClick } : { subOnClick }),
      });

      const to: LocationDescriptor<LocationState> = {
        pathname: query ? path?.split('?')[0] : path,
        search: query,
        state: Object.keys(state).length ? state : undefined,
      };

      // In the case is a navigation with the router, we return all information needed for routing
      return {
        href: to.pathname,
        target: '_self',
        onClickWithoutRouting:
          displayMode === Template.Fullscreen || objKey === StrateMode.LiveTv
            ? (event) => {
                event.persist();
                // TODO: refacto data type
                dispatch(
                  launchPlayerFullScreen({
                    event,
                    data: data as any,
                    type: objKey,
                  })
                );
                const { trackingContext } = data?.mainOnClick || {};
                const launchPlayerPageName = `${brand} - Live TV - Lecture Player Live`;

                if (isTvDevice) {
                  // TODO: Delete this condition when OneCore Will be ready to handle this case
                  trackSendPageView({
                    data: {
                      ...trackingContext,
                      ...(trackingContext?.context_type && {
                        prop17: trackingContext?.context_type,
                      }),
                      ...(trackingContext?.contextDetail && {
                        prop18: trackingContext?.contextDetail,
                      }),
                      eVar37: 'Live',
                      eVar38: 'Regarder Live',
                      themeColor: ThemeColor.Dark,
                      profile_kids: isKids,
                      page_name: launchPlayerPageName,
                    },
                    name: launchPlayerPageName,
                  });
                }
                handleClick(event);
              }
            : handleClick,
        onClick:
          displayMode === Template.Fullscreen || objKey === StrateMode.LiveTv
            ? (event) => {
                event.preventDefault();
                event.persist();
                // TODO: refacto data type
                dispatch(
                  launchPlayerFullScreen({
                    event,
                    data: data as any,
                    type: objKey,
                  })
                );
                handleClick(event);
                redirectRouter(history, to, replace);
              }
            : (event) => {
                event.preventDefault();
                handleClick(event);
                redirectRouter(history, to, replace);
              },
        to,
      };
    },
    [authenticate, dispatch, handleConnectClick, history, store]
  );

  return { getLinkerSettings };
};

export default useLinker;
