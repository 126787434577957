import { useSelector } from 'react-redux';
import { useAppHistory } from '../../helpers/hooks/reactRouter';
import { useActionCreators } from '../../helpers/hooks/useActionCreators';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { isMobileSelector } from '../../store/slices/application-selectors';
import { closeModal } from '../../store/slices/modal';
import {
  isModalOpenSelector,
  modalClickedElementSelector,
  modalPropsSelector,
  modalTypeSelector,
} from '../../store/slices/modal-selectors';
import ModalContainer, { ModalContainerProps } from './ModalContainer';

export function ModalContainerConnected(
  props: Omit<
    ModalContainerProps,
    | 'clickedElement'
    | 'closeAction'
    | 'dark'
    | 'history'
    | 'isMobile'
    | 'isOpen'
    | 'modalProps'
    | 'modalType'
  >
): JSX.Element {
  const history = useAppHistory();

  const clickedElement = useSelector(modalClickedElementSelector);
  const isMobile = useInvariantSelector(isMobileSelector);
  const isOpen = useSelector(isModalOpenSelector);
  const modalProps = useSelector(modalPropsSelector);
  const modalType = useSelector(modalTypeSelector);

  const actions = useActionCreators({ closeAction: closeModal });

  return (
    <ModalContainer
      {...props}
      {...actions}
      clickedElement={clickedElement}
      history={history}
      isMobile={isMobile}
      isOpen={isOpen}
      modalProps={modalProps}
      modalType={modalType}
    />
  );
}
