import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getDocumentHeaderMetasType } from '../../helpers/metaTags/metaTags-helper';
import {
  applicationDescriptionSelector,
  offerLocationSelector,
} from '../../store/slices/application-selectors';
import { pageDisplayNameSelector } from '../../store/slices/page-selectors';
import { useMetas } from './useMetas';

/**
 * Define metas for title, description and image of template from mainOnClick
 */
export function MetaTagsDefaultTemplate() {
  const offerLocation = useSelector(offerLocationSelector);
  const displayName = useSelector(pageDisplayNameSelector) || '';
  const applicationDescription = useSelector(applicationDescriptionSelector);

  const documentHeader = getDocumentHeaderMetasType(offerLocation);

  // defaut image meta tag
  const urlImageDefault = documentHeader?.app.head?.twitter?.urlImageDefault;
  const urlImage = documentHeader?.app.head.meta.find(
    (headerMeta) => headerMeta.property === 'og:image'
  )?.content;

  const metas = useMetas({
    title: displayName,
    description: applicationDescription,
    urlImage: urlImage ?? urlImageDefault,
  });

  return (
    <Helmet
      title={
        metas.find((meta) => meta.property === 'title')?.content || undefined
      }
      meta={metas}
    />
  );
}
