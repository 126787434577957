import { loginUser } from '@canalplus/ifc-onecore';
import { isClientSide } from '@canalplus/mycanal-commons';
import { getSigninUrl } from '@canalplus/sdk-pass';
import { usePassSdk } from '@canalplus/sdk-pass-react';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';

/** Redirect to the authentication page */
export const useSigninRedirect = (): ((redirectUri?: string) => void) => {
  const signinUrl = usePassSdk(getSigninUrl);
  const isTvDevice = useIsTvDevice();

  return (redirectUri = isClientSide() ? window.location.href : '') => {
    if (isTvDevice) {
      loginUser();
      return;
    }

    window.location.assign(signinUrl({ redirectUri }));
  };
};
