import classNames from 'classnames';
import type { AriaAttributes } from 'react';
import { cloneElement, forwardRef } from 'react';
import type { HeaderUserDropdownListItem as HeaderUserDropdownListItemType } from '../HeaderUserDropdown/HeaderUserDropdown.types';

type Ref = HTMLDivElement & HTMLButtonElement & HTMLAnchorElement;

type HeaderUserDropdownListItemProps = {
  /**
   * Is the item focused
   */
  isFocused?: boolean;
} & HeaderUserDropdownListItemType &
  AriaAttributes;

const HeaderUserDropdownListItem = forwardRef<
  Ref,
  Partial<HeaderUserDropdownListItemProps>
>(
  (
    { label, id, href, wrapper, icon, isFocused, ...props },
    forwardedRef
  ): JSX.Element => {
    const style = classNames(
      'group flex items-center cursor-pointer no-underline box-border hover:outline-none',
      'font-dt-font-family-system font-dt-font-weight-regular',
      'text-dt-font-size-16 leading-dt-font-line-height-20',
      'h-32 px-dt-spacing-200 space-x-dt-spacing-100 w-full',
      'text-dt-theme-text-list-row-list-title transition-colors duration-200',
      'hover:bg-dt-theme-surface-card-tab-card-tab-hover focus:bg-dt-theme-surface-card-tab-card-tab-hover',
      {
        'bg-dt-theme-surface-card-tab-card-tab-hover': isFocused,
      }
    );

    const listItemIcon = (iconItem: React.ReactElement) =>
      cloneElement(iconItem, {
        className: classNames(
          'h-26 basis-26 grow-0 shrink-0 transition-colors duration-200'
        ),
      });

    const listItemChildren = (
      <>
        {icon && listItemIcon(icon)}
        <span className="text-dt-font-size-16 leading-dt-font-line-height-20">
          {label}
        </span>
      </>
    );

    if (wrapper) {
      return cloneElement(
        wrapper,
        // eslint-disable-next-line react-compiler/react-compiler
        {
          id,
          className: style,
          ref: forwardedRef,
          ...props,
        },
        listItemChildren
      );
    }

    return (
      <a ref={forwardedRef} href={href} id={id} className={style} {...props}>
        {listItemChildren}
      </a>
    );
  }
);

HeaderUserDropdownListItem.displayName = 'HeaderUserDropdownListItem';

export default HeaderUserDropdownListItem;
