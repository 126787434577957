import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  AUTHENTICATION,
  DOWNLOAD_MANAGER,
  EXTERNAL_SITE,
  LAUNCH_ONE_SHOP,
  SELECT_REGION,
} from '../../constants/links';

/**
 * Props
 *
 * <Linker /> is a react router wrapper
 * It is specifically tailored to meet our needs
 * It helps spread the onClick ref of each link into the state when changing routes
 *
 * @type {object} data              - obj containing the onClick key to spread in state
 * @type {node}   children          - DOM children elements
 * @type {string} title             - title to pass down to <Link> component
 * @type {string} className         - classes to pass down to <Link> component
 * @type {string} objKey            - name of key to use on {data} to spread in state (eg: ...data[objKey])
 * @type {bool}   replace           - whether we want to replace the history entry instead of adding a new one
 */
export const Linker = memo(
  ({
    ariaLabel,
    ariaLabelledBy,
    children,
    className,
    data = {},
    id,
    objKey,
    onClick,
    onConnectClick,
    onOneShopClick,
    onSelectRegionClick,
    replace,
    scrollableElementId,
    scrollLogicalPosition,
    target,
    title,
    isTvDevice = false,
    ...rest
  }) => {
    const location = useLocation();

    const { mainOnClick: dataOnClick } = data || {};
    const { path, displayTemplate } = dataOnClick || {};
    const newTarget = isTvDevice ? '_self' : dataOnClick?.target || target;

    const handleClick = useCallback(
      (e) => {
        if (onClick) {
          onClick(e);
        }
      },
      [onClick]
    );

    if (
      displayTemplate === EXTERNAL_SITE ||
      displayTemplate === DOWNLOAD_MANAGER
    ) {
      return (
        <a
          onClick={handleClick}
          href={dataOnClick?.URLWebsite || dataOnClick?.URLPage}
          className={className}
          target={newTarget}
          id={id}
          aria-label={ariaLabel}
          aria-labelledby={ariaLabelledBy}
        >
          {children}
        </a>
      );
    }

    if (displayTemplate === AUTHENTICATION) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          id={id}
          className={className}
          onClick={onConnectClick}
          role="button"
          target={newTarget}
        >
          {children}
        </a>
      );
    }

    if (displayTemplate === LAUNCH_ONE_SHOP) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          id={id}
          className={className}
          onClick={() => {
            onOneShopClick(dataOnClick?.contentID);
          }}
          role="button"
          target={newTarget}
        >
          {children}
        </a>
      );
    }

    if (displayTemplate === SELECT_REGION) {
      return (
        <button
          id={id}
          className={className}
          onClick={() => {
            onSelectRegionClick(dataOnClick?.contentID);
          }}
          type="button"
          target={newTarget}
        >
          {children}
        </button>
      );
    }

    /**
     * `onClickPage`, `onClickImmersive` are the new way of handling the linker's onClick state /
     * They replace the legacy onClick retrieved in objKeyData thanks to the objKey /
     * However, the legacy onClick ([objKey]: objKeyData) will not be deleted yet for retrocompatibility purposes
     * It is from now on @deprecated, and we encourage moving to the use of the new "onClicks"
     *
     * `onClickImmersive` is defined when the linker open's an immersive
     * otherwise, onClickPage is defined otherwise
     *
     * `immersiveStackSize` is an integer that allows keeping track of the number of immersives opened in a row
     * It is useful for:
     * - displaying or not the back arrow icon on an immersive
     * - closing all immersives at once from the the close icon of an immersive
     */
    const state = {
      ...(dataOnClick?.context === 'immersive' && {
        immersive: {
          mainOnClick: dataOnClick,
          stackSize: (location?.state?.immersive?.stackSize || 0) + 1,
        },
      }),
      ...(dataOnClick?.context !== 'immersive' && {
        page: { mainOnClick: dataOnClick },
      }),
    };

    return (
      <Link
        data-testid="link-testid"
        className={className}
        onClick={handleClick}
        to={{
          pathname: path,
          state: Object.keys(state).length ? state : null,
        }}
        title={title}
        replace={replace}
        id={id}
        {...rest}
      >
        {children}
      </Link>
    );
  }
);

const LinkerPropTypes = {
  data: PropTypes.shape({
    onClick: PropTypes.shape({
      path: PropTypes.string,
    }),
    type: PropTypes.string,
    /* may have additional properties */
  }).isRequired,
  children: PropTypes.node,
  target: PropTypes.string,
  onClick: PropTypes.func,
  onConnectClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  objKey: PropTypes.string,
  replace: PropTypes.bool,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  isTvDevice: PropTypes.bool,
};

Linker.propTypes = LinkerPropTypes;

Linker.defaultProps = {
  objKey: 'onClick',
  target: '_blank', // if tvDevice _self else _blank
  children: undefined,
  replace: false,
  onConnectClick: () => null,
  onClick: undefined,
  title: undefined,
  className: '',
  id: undefined,
  ariaLabel: '',
  ariaLabelledBy: '',
  isTvDevice: false,
};

Linker.displayName = 'Linker';
