import classNames from 'classnames';
import type { HeaderUserDropdownMenuProps } from './HeaderUserDropdownMenu.types';

/**
 * A `HeaderUserDropdownMenu` is a user component for the `HeaderUserDropdown`. It is used to display
 * a menu with items to be displayed in the dropdown.
 *
 * @param props.children List of items to display in the menu
 * @returns Menu of items to be displayed in the dropdown
 * @example <HeaderUserDropdownMenu>{children}</HeaderUserDropdownMenu>
 */
export function HeaderUserDropdownMenu({
  children,
}: HeaderUserDropdownMenuProps): JSX.Element {
  return (
    <div
      className={classNames(
        'flex flex-col bg-dt-theme-surface-profile-card-profile-card shadow-card outline-none no-scrollbar overflow-y-auto',
        'w-full dt-breakpoint-sm-start:w-[20rem] dt-breakpoint-sm-start:max-w-[20rem]',
        'space-y-dt-spacing-200 py-dt-spacing-400 dt-breakpoint-sm-start:py-dt-spacing-200',
        'dt-breakpoint-sm-start:rounded-dt-radius-large',
        'fixed dt-breakpoint-sm-start:static',
        'top-36 bottom-0 dt-breakpoint-sm-start:inset-y-auto'
      )}
    >
      {children}
    </div>
  );
}
