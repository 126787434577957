import { Type } from '@canalplus/mycanal-commons';
import type { Key, VirtualKeyboardState } from './types';
import { Mode } from './types';

export const TO_HIDE_IN_SPECIAL_MODE = 'to_hide_in_special_mode';

// BASIC KEYS
// Used in both international & polish layout

export const SPACE_KEY: Key = {
  id: 'kb-space',
  sign: '',
};

export const CLEAR_KEY: Key = {
  id: 'kb-clear',
  sign: '',
};

export const UPPER_KEY: Key = {
  id: 'kb-upper',
  sign: 'ABC',
  special: 'ABC',
};

export const LOWER_KEY: Key = {
  id: 'kb-lower',
  sign: 'abc',
  special: 'abc',
};

export const SPECIALS_KEY: Key = {
  id: 'kb-specials',
  sign: '&+/',
};

export const PREV_KEY: Key = {
  id: 'kb-prev',
  sign: '',
};

export const NEXT_KEY: Key = {
  id: 'kb-next',
  sign: '',
};

const A_KEY: Key = {
  id: 'kb-a',
  sign: 'A',
  special: '£',
};

// INTERNATIONAL KEYS
// Used in international layout only

const B_KEY: Key = {
  id: 'kb-b',
  sign: 'B',
  special: '*',
};

const C_KEY: Key = {
  id: 'kb-c',
  sign: 'C',
  special: '=',
};

const D_KEY: Key = {
  id: 'kb-d',
  sign: 'D',
  special: '+',
};

const E_KEY: Key = {
  id: 'kb-e',
  sign: 'E',
  special: ';',
};

const F_KEY: Key = {
  id: 'kb-f',
  sign: 'F',
  special: ':',
};

const G_KEY: Key = {
  id: 'kb-g',
  sign: 'G',
  special: 'ç',
};

const H_KEY: Key = {
  id: 'kb-h',
  sign: 'H',
  special: '?',
};

const I_KEY: Key = {
  id: 'kb-i',
  sign: 'I',
  special: '/',
};

const J_KEY: Key = {
  id: 'kb-j',
  sign: 'J',
  special: '°',
};

const K_KEY: Key = {
  id: 'kb-k',
  sign: 'K',
  special: '(',
};

const L_KEY: Key = {
  id: 'kb-l',
  sign: 'L',
  special: ')',
};

const M_KEY: Key = {
  id: 'kb-m',
  sign: 'M',
  special: '"',
};

const N_KEY: Key = {
  id: 'kb-n',
  sign: 'N',
  special: "'",
};

const O_KEY: Key = {
  id: 'kb-o',
  sign: 'O',
  special: '[',
};

const P_KEY: Key = {
  id: 'kb-p',
  sign: 'P',
  special: ']',
};

const Q_KEY: Key = {
  id: 'kb-q',
  sign: 'Q',
  special: '§',
};

const R_KEY: Key = {
  id: 'kb-r',
  sign: 'R',
  special: 'ê',
};

const S_KEY: Key = {
  id: 'kb-s',
  sign: 'S',
  special: 'è',
};

const T_KEY: Key = {
  id: 'kb-t',
  sign: 'T',
  special: 'é',
};

const U_KEY: Key = {
  id: 'kb-u',
  sign: 'U',
  special: 'ù',
};

const V_KEY: Key = {
  id: 'kb-v',
  sign: 'V',
  special: 'à',
};

const W_KEY: Key = {
  id: 'kb-w',
  sign: 'W',
  special: 'ô',
};

const X_KEY: Key = {
  id: 'kb-x',
  sign: 'X',
  special: 'î',
};

const Y_KEY: Key = {
  id: 'kb-y',
  sign: 'Y',
  special: 'ï',
};

const Z_KEY: Key = {
  id: 'kb-z',
  sign: 'Z',
  special: 'ö',
};

const ZERO_KEY: Key = {
  id: 'kb-0',
  sign: '0',
  special: '$',
  type: Type.Number,
};

const ONE_KEY: Key = {
  id: 'kb-1',
  sign: '1',
  special: '€',
  type: Type.Number,
};

const TWO_KEY: Key = {
  id: 'kb-2',
  sign: '2',
  special: '\\',
  type: Type.Number,
};

const THREE_KEY: Key = {
  id: 'kb-3',
  sign: '3',
  special: '{',
  type: Type.Number,
};

const FOUR_KEY: Key = {
  id: 'kb-4',
  sign: '4',
  special: '}',
  type: Type.Number,
};

const FIVE_KEY: Key = {
  id: 'kb-5',
  sign: '5',
  special: '!',
  type: Type.Number,
};

const SIX_KEY: Key = {
  id: 'kb-6',
  sign: '6',
  special: '&',
  type: Type.Number,
};

const SEVEN_KEY: Key = {
  id: 'kb-7',
  sign: '7',
  special: '%',
  type: Type.Number,
};

const HEIGHT_KEY: Key = {
  id: 'kb-8',
  sign: '8',
  special: '#',
  type: Type.Number,
};

const NINE_KEY: Key = {
  id: 'kb-9',
  sign: '9',
  special: '~',
  type: Type.Number,
};

const AT_KEY: Key = {
  id: 'kb-at',
  sign: '@',
  special: '^',
};

const DOT_KEY: Key = {
  id: 'kb-dot',
  sign: '.',
  special: '<',
};

const MIDLINE_KEY: Key = {
  id: 'kb-midline',
  sign: '-',
  special: '>',
};

const LINE_KEY: Key = {
  id: 'kb-line',
  sign: '_',
  special: ',',
};

// POLISH KEYS
// Used in polish layout only

const POLISH_TAIL_A_KEY: Key = {
  id: 'kb-tail-a',
  sign: 'Ą',
  special: '*',
};

const POLISH_B_KEY: Key = {
  id: 'kb-b',
  sign: 'B',
  special: '=',
};

const POLISH_C_KEY: Key = {
  id: 'kb-c',
  sign: 'C',
  special: '+',
};

const POLISH_ACUTE_C_KEY: Key = {
  id: 'kb-acute-c',
  sign: 'Ć',
  special: ';',
};

const POLISH_D_KEY: Key = {
  id: 'kb-d',
  sign: 'D',
  special: ':',
};

const POLISH_E_KEY: Key = {
  id: 'kb-e',
  sign: 'E',
  special: '?',
};

const POLISH_TAIL_E_KEY: Key = {
  id: 'kb-tail-e',
  sign: 'Ę',
  special: '/',
};

const POLISH_F_KEY: Key = {
  id: 'kb-f',
  sign: 'F',
  special: '°',
};

const POLISH_G_KEY: Key = {
  id: 'kb-g',
  sign: 'G',
  special: '(',
};

const POLISH_H_KEY: Key = {
  id: 'kb-h',
  sign: 'H',
  special: ')',
};

const POLISH_I_KEY: Key = {
  id: 'kb-i',
  sign: 'I',
  special: '"',
};

const POLISH_J_KEY: Key = {
  id: 'kb-j',
  sign: 'J',
  special: "'",
};

const POLISH_K_KEY: Key = {
  id: 'kb-k',
  sign: 'K',
  special: '[',
};

const POLISH_L_KEY: Key = {
  id: 'kb-l',
  sign: 'L',
  special: ']',
};

const POLISH_STROKE_L_KEY: Key = {
  id: 'kb-stroke-l',
  sign: 'Ł',
  special: '§',
};

const POLISH_M_KEY: Key = {
  id: 'kb-m',
  sign: 'M',
  special: ',',
};

const POLISH_N_KEY: Key = {
  id: 'kb-n',
  sign: 'N',
  special: '^',
};

const POLISH_ACUTE_N_KEY: Key = {
  id: 'kb-acute-n',
  sign: 'Ń',
  special: 'ê',
};

const POLISH_O_KEY: Key = {
  id: 'kb-o',
  sign: 'O',
  special: 'î',
};

const POLISH_ACUTE_O_KEY: Key = {
  id: 'kb-acute-o',
  sign: 'Ó',
  special: 'ï',
};

const POLISH_P_KEY: Key = {
  id: 'kb-p',
  sign: 'P',
  special: 'ö',
};

const POLISH_Q_KEY: Key = {
  id: 'kb-q',
  sign: 'Q',
  special: '$',
};

const POLISH_R_KEY: Key = {
  id: 'kb-r',
  sign: 'R',
  special: '\\',
};

const POLISH_S_KEY: Key = {
  id: 'kb-s',
  sign: 'S',
  special: '{',
};

const POLISH_ACUTE_S_KEY: Key = {
  id: 'kb-acute-s',
  sign: 'Ś',
  special: '}',
};

const POLISH_T_KEY: Key = {
  id: 'kb-t',
  sign: 'T',
  special: '!',
};

const POLISH_U_KEY: Key = {
  id: 'kb-u',
  sign: 'U',
  special: '&',
};

const POLISH_V_KEY: Key = {
  id: 'kb-v',
  sign: 'V',
  special: '%',
};

const POLISH_W_KEY: Key = {
  id: 'kb-w',
  sign: 'W',
  special: '#',
};

const POLISH_X_KEY: Key = {
  id: 'kb-x',
  sign: 'X',
  special: '~',
};

const POLISH_Y_KEY: Key = {
  id: 'kb-y',
  sign: 'Y',
  special: '<',
};

const POLISH_Z_KEY: Key = {
  id: 'kb-z',
  sign: 'Z',
  special: '>',
};

const POLISH_ACUTE_Z_KEY: Key = {
  id: 'kb-acute-z',
  sign: 'Ź',
  special: TO_HIDE_IN_SPECIAL_MODE,
};

const POLISH_OVERDOT_Z_KEY: Key = {
  id: 'kb-overdot-z',
  sign: 'Ż',
  special: TO_HIDE_IN_SPECIAL_MODE,
};

const POLISH_ZERO_KEY: Key = {
  id: 'kb-0',
  sign: '0',
  special: '0',
  type: Type.Number,
};

const POLISH_ONE_KEY: Key = {
  id: 'kb-1',
  sign: '1',
  special: '1',
  type: Type.Number,
};

const POLISH_TWO_KEY: Key = {
  id: 'kb-2',
  sign: '2',
  special: '2',
  type: Type.Number,
};

const POLISH_THREE_KEY: Key = {
  id: 'kb-3',
  sign: '3',
  special: '3',
  type: Type.Number,
};

const POLISH_FOUR_KEY: Key = {
  id: 'kb-4',
  sign: '4',
  special: '4',
  type: Type.Number,
};

const POLISH_FIVE_KEY: Key = {
  id: 'kb-5',
  sign: '5',
  special: '5',
  type: Type.Number,
};

const POLISH_SIX_KEY: Key = {
  id: 'kb-6',
  sign: '6',
  special: '6',
  type: Type.Number,
};

const POLISH_SEVEN_KEY: Key = {
  id: 'kb-7',
  sign: '7',
  special: '7',
  type: Type.Number,
};

const POLISH_HEIGHT_KEY: Key = {
  id: 'kb-8',
  sign: '8',
  special: '8',
  type: Type.Number,
};

const POLISH_NINE_KEY: Key = {
  id: 'kb-9',
  sign: '9',
  special: '9',
  type: Type.Number,
};

const POLISH_AT_KEY: Key = {
  id: 'kb-at',
  sign: '@',
  special: '@',
};

const POLISH_DOT_KEY: Key = {
  id: 'kb-dot',
  sign: '.',
  special: '.',
};

const POLISH_MIDLINE_KEY: Key = {
  id: 'kb-midline',
  sign: '-',
  special: '-',
};

const POLISH_LINE_KEY: Key = {
  id: 'kb-line',
  sign: '_',
  special: '_',
};

export const CONTENT_KEYS: Key[] = [
  A_KEY,
  B_KEY,
  C_KEY,
  D_KEY,
  E_KEY,
  F_KEY,
  G_KEY,
  H_KEY,
  I_KEY,
  J_KEY,
  K_KEY,
  L_KEY,
  M_KEY,
  NEXT_KEY,
  N_KEY,
  O_KEY,
  P_KEY,
  Q_KEY,
  R_KEY,
  S_KEY,
  T_KEY,
  U_KEY,
  V_KEY,
  W_KEY,
  X_KEY,
  Y_KEY,
  Z_KEY,
  PREV_KEY,
  ZERO_KEY,
  ONE_KEY,
  TWO_KEY,
  THREE_KEY,
  FOUR_KEY,
  FIVE_KEY,
  SIX_KEY,
  SEVEN_KEY,
  HEIGHT_KEY,
  NINE_KEY,
  AT_KEY,
  DOT_KEY,
  MIDLINE_KEY,
  LINE_KEY,
];

export const POLISH_CONTENT_KEYS: Key[] = [
  A_KEY,
  POLISH_TAIL_A_KEY,
  POLISH_B_KEY,
  POLISH_C_KEY,
  POLISH_ACUTE_C_KEY,
  POLISH_D_KEY,
  POLISH_E_KEY,
  POLISH_TAIL_E_KEY,
  POLISH_F_KEY,
  POLISH_G_KEY,
  POLISH_H_KEY,
  POLISH_I_KEY,
  POLISH_J_KEY,
  POLISH_K_KEY,
  POLISH_L_KEY,
  POLISH_STROKE_L_KEY,
  POLISH_M_KEY,
  POLISH_N_KEY,
  NEXT_KEY,
  POLISH_ACUTE_N_KEY,
  POLISH_O_KEY,
  POLISH_ACUTE_O_KEY,
  POLISH_P_KEY,
  POLISH_Q_KEY,
  POLISH_R_KEY,
  POLISH_S_KEY,
  POLISH_ACUTE_S_KEY,
  POLISH_T_KEY,
  POLISH_U_KEY,
  POLISH_V_KEY,
  POLISH_W_KEY,
  POLISH_X_KEY,
  POLISH_Y_KEY,
  POLISH_Z_KEY,
  POLISH_ACUTE_Z_KEY,
  POLISH_OVERDOT_Z_KEY,
  PREV_KEY,
];

export const POLISH_LAST_ROW_CONTENT_KEYS: Key[] = [
  POLISH_ZERO_KEY,
  POLISH_ONE_KEY,
  POLISH_TWO_KEY,
  POLISH_THREE_KEY,
  POLISH_FOUR_KEY,
  POLISH_FIVE_KEY,
  POLISH_SIX_KEY,
  POLISH_SEVEN_KEY,
  POLISH_HEIGHT_KEY,
  POLISH_NINE_KEY,
  POLISH_AT_KEY,
  POLISH_DOT_KEY,
  POLISH_MIDLINE_KEY,
  POLISH_LINE_KEY,
];

export const FOOTER_KEYS: Key[] = [UPPER_KEY, LOWER_KEY, SPECIALS_KEY];

export const INITIAL_STATE: VirtualKeyboardState = {
  cursorIndex: 0,
  inputValue: [],
  mode: Mode.LOWERCASE,
};
