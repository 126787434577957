/**
 * Drives the characters that will be available on the keyboard
 *
 * - `Number` type keyboard will only have number keys available
 * - `Email` type keyboard will have special characters to ease email input
 */
export enum Type {
  Number = 1,
  Email,
}
