import { createContext, useMemo, useReducer } from 'react';
import { setInputValue } from './actions';
import { INITIAL_STATE } from './const';
import { reducer } from './reducer';
import type { Action, VirtualKeyboardState } from './types';

export interface IVirtualKeyboardContext {
  state: VirtualKeyboardState;
  dispatch: React.Dispatch<Action>;
  setInputValue: (inputValue: string) => void;
}

const virtualKeyboardContext = createContext({} as IVirtualKeyboardContext);

/**
 * VirtualKeyboardProvider
 * --------------------
 *
 * Component used to provide the **virtualKeyboard context** in all of its chidren
 *
 * @param children Any `ReactNode` children
 */
function VirtualKeyboardProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const value = useMemo(
    () => ({
      state,
      dispatch,
      setInputValue: (inputValue: string) =>
        dispatch(setInputValue(inputValue.split(''))),
    }),
    [state]
  );

  return (
    <virtualKeyboardContext.Provider value={value}>
      {children}
    </virtualKeyboardContext.Provider>
  );
}

export { VirtualKeyboardProvider, virtualKeyboardContext };
