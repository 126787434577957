/**
 * Raw ACM API paths\
 * Some placeholders has to be filled
 *
 * - **{offerZone}**
 * - **{offerLocation}**
 */
export const acmApiPath = {
  changeParentalCodeActivation:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/parentalCode/activation',
  checkHealthStatus: '/api/health',
  checkParentalCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/parentalCode/check',
  checkPurchaseCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/purchaseCode/check',
  deleteParentalCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/parentalCode',
  deletePurchaseCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/purchaseCode/status',
  getParentalCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/parentalCode',
  getParentalCodeStatus:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/parentalCode/status',
  getPurchaseCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/purchaseCode',
  getPurchaseCodeStatus:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/purchaseCode/status',
  updateParentalCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/parentalCode',
  updatePurchaseCode:
    '/api/v2/offerZones/{offerZone}/offerLocation/{offerLocation}/purchaseCode',
} as const;

export type AcmApi = keyof typeof acmApiPath;
