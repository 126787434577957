import classNames from 'classnames';
import { useState } from 'react';
import IconArrow from '../../../assets/svg/arrow.svg';
import IconClear from '../../../assets/svg/clear.svg';
import {
  CLEAR_KEY,
  LOWER_KEY,
  NEXT_KEY,
  PREV_KEY,
  SPACE_KEY,
  SPECIALS_KEY,
  UPPER_KEY,
} from '../const';
import { getFormatedSign } from '../helpers';
import type { Key, Mode } from '../types';
import styles from './VirtualKeyboardKey.module.css';

export type VirtualKeyboardKeyProps = {
  keyProp: Key;
  pressKey: (T: string) => void;
  isActive?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  layout?: 'normal' | 'polish' | 'big' | string[];
  mode?: Mode;
};

export function VirtualKeyboardKey({
  isActive,
  isDisabled,
  isSelected,
  keyProp,
  layout,
  mode,
  pressKey,
}: VirtualKeyboardKeyProps): JSX.Element {
  const { id, sign } = keyProp;
  const formatedSign = getFormatedSign(keyProp, mode);

  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    if (isDisabled) {
      return;
    }

    pressKey(id);

    setIsClicked(true);
    setTimeout(() => setIsClicked(false), 300);
  };

  return (
    <button
      type="button"
      id={!isDisabled ? id : undefined}
      className={classNames(styles.virtualKeyboardKey, {
        [styles.virtualKeyboardKey__isActive]: isActive,
        [styles.virtualKeyboardKey__isBackspace]: id === SPACE_KEY.id,
        [styles.virtualKeyboardKey__isClear]: id === CLEAR_KEY.id,
        [styles.virtualKeyboardKey__isClicked]: isClicked,
        [styles.virtualKeyboardKey__isDisabled]: isDisabled,
        [styles.virtualKeyboardKey__isFooter]:
          id === UPPER_KEY.id || id === LOWER_KEY.id || id === SPECIALS_KEY.id,
        [styles.virtualKeyboardKey__isNextArrow]: id === NEXT_KEY.id,
        [styles.virtualKeyboardKey__isPrevArrow]: id === PREV_KEY.id,
        [styles.virtualKeyboardKey__isSelected]: isSelected,
        [styles.virtualKeyboardKey__isSpecial]: sign.length > 1,
      })}
      data-tv-focusmode="attribute"
      data-disabled={isDisabled || undefined}
      onClick={handleClick}
      data-layout={layout}
      disabled={isDisabled}
    >
      {formatedSign}

      {id === PREV_KEY.id && (
        <IconArrow className={styles.virtualKeyboardKey__svg__isPrev} />
      )}
      {id === NEXT_KEY.id && (
        <IconArrow className={styles.virtualKeyboardKey__svg__isNext} />
      )}
      {id === CLEAR_KEY.id && (
        <IconClear className={styles.virtualKeyboardKey__svg__isClear} />
      )}
    </button>
  );
}
