import type { DiveSVG } from '@canalplus/dive';
import {
  ShareDailymotionSvg,
  ShareFacebookSvg,
  ShareInstagramSvg,
  ShareLinkedInSvg,
  ShareTiktokSvg,
  ShareTwitterSvg,
  ShareYoutubeSvg,
} from '@canalplus/dive';
import type { FormattedSocialLink } from '../types';
import styles from './Socials.module.css';
import type { SocialNetwork } from './types';
import { isSocialNetwork } from './types';

export type SocialsProps = {
  socials: FormattedSocialLink[];
};

const FOOTER_SOCIAL_NETWORK_ICONS: Record<SocialNetwork, DiveSVG> = {
  'Dailymotion': ShareDailymotionSvg,
  'Facebook': ShareFacebookSvg,
  'Instagram': ShareInstagramSvg,
  'LinkedIn': ShareLinkedInSvg,
  'Twitter': ShareTwitterSvg,
  'Youtube': ShareYoutubeSvg,
  'Tiktok': ShareTiktokSvg,
};

function Socials({ socials }: SocialsProps): JSX.Element {
  return (
    <ul className={styles.socials}>
      {socials.map((social) => {
        const { link, displayName } = social;

        // do not display socials without urls
        if (link && displayName) {
          const iconTemplate = `${displayName.charAt(0).toUpperCase()}${displayName.slice(1)}`;

          if (isSocialNetwork(iconTemplate)) {
            const SocialIcon = FOOTER_SOCIAL_NETWORK_ICONS[iconTemplate];

            return (
              <li
                key={`social-${displayName}`}
                className={styles.socials__items}
              >
                <a
                  href={link}
                  title={displayName}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className={styles.socials__socialIcon}>
                    <SocialIcon />
                  </span>
                </a>
              </li>
            );
          }
        }
        return null;
      })}
    </ul>
  );
}

export default Socials;
