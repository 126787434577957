import { canUseRaf } from './canUseRaf';

/**
 * Window Helper
 * --------------------
 *
 * Based on `canUseRaf()`, execute a callback\
 * after the delay passed in parameter
 *
 * It makes sure that the DOM is ready before manipulation
 *
 * Useful inside `componentDidMount`
 *
 * @param callback The **callback** to execute
 * @param delay The **delay** to wait before executing the callback
 * @return A `Timeout` ID
 *
 * @category Window
 */

export const executeOnNextFrame = (
  callback: () => void,
  delay = 0
): NodeJS.Timeout => {
  if (canUseRaf()) {
    return setTimeout(() => window.requestAnimationFrame(callback), delay);
  }

  return setTimeout(callback, delay);
};
