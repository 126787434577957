import classNames from 'classnames';
import { Button } from '../../Actions/Button/Button';
import { DropdownMenuItem } from '../../Dropdown/DropdownMenuItem';
import type { HeaderUserDropdownButtonProps } from './HeaderUserDropdownButton.types';

/**
 * Button component for the `HeaderUserDropdownMenu`.
 *  - The button is wrapped in a `DropdownMenuItem` to handle the dropdown menu logic.
 *  - By default, the button is styled with a `ButtonWidth.Full` and a `DiveFontFamily.Hind` font.
 * @returns A card with a title, subtitle, and a dive Button
 * @example <HeaderUserDropdownButton label="Login" onClick={handleLogin} title="Welcome" subtitle="Sign in to access your account" />
 */
export function HeaderUserDropdownButton({
  title,
  subtitle,
  label,
  onClick,
  ...buttonProps
}: HeaderUserDropdownButtonProps): JSX.Element {
  return (
    <div className={classNames('flex flex-col px-dt-spacing-200 outline-none')}>
      {title && (
        <span className="text-dt-font-size-22 leading-dt-font-line-height-26 font-dt-font-family-system font-dt-font-weight-semibold text-dt-theme-text-text-100 mb-dt-spacing-100">
          {title}
        </span>
      )}
      {subtitle && (
        <span className="text-dt-font-size-18 leading-dt-font-line-height-22 font-dt-font-family-system text-dt-theme-text-text-100 mb-dt-spacing-200">
          {subtitle}
        </span>
      )}

      <DropdownMenuItem
        label={label}
        onClick={onClick}
        renderComponent={({ itemProps }) => (
          <Button width="full" font="hind" {...buttonProps} {...itemProps}>
            {label}
          </Button>
        )}
      />
    </div>
  );
}
