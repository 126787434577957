import { Avatar } from '@canalplus/dive';
import { DIMENSIONS, ProfileModal } from '@canalplus/mycanal-commons';
import { Binder } from '@canalplus/one-navigation';
import { ApiV2AvatarV1Content } from '@dce-front/hodor-types/api/v2/avatars/v1/definitions';
import { ApiV2Profile } from '@dce-front/hodor-types/api/v2/me/profiles/definitions';
import classNames from 'classnames';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { MIDDLEWARE_SETTINGS } from '../../../helpers/oneNavigation/middleware';
import I18n from '../../../lang';
import {
  setAvatar,
  setProfileView,
  setSelectedProfile,
} from '../../../store/slices/profilesModal';
import {
  modalIsFromLandingSelector,
  selectedProfileSelector,
} from '../../../store/slices/profilesModal-selectors';
import ThumborMediaImage from '../../ThumborMediaImage/ThumborMediaImage';
import styles from './AvatarSelection.css';

type AvatarSelectionProps = {
  avatars: ApiV2AvatarV1Content[];
};

function AvatarSelection({ avatars = [] }: AvatarSelectionProps): JSX.Element {
  const { t } = useContext(I18n.context);

  const dispatch = useAppDispatch();
  const profileSelected = useSelector(selectedProfileSelector);
  const modalIsFromLanding = useSelector(modalIsFromLandingSelector);

  const onAvatarSelection = (avatar: string, profile?: ApiV2Profile) => {
    const creationView = modalIsFromLanding
      ? ProfileModal.ProfileCreationViewFromLanding
      : ProfileModal.ProfileCreationView;
    const profileModalView = profile
      ? ProfileModal.ProfileEditionView
      : creationView;

    dispatch(setAvatar(avatar));
    dispatch(setProfileView(profileModalView));

    if (profile) {
      dispatch(setSelectedProfile(profile));
    }
  };

  return (
    <div className={styles.avatarSelection}>
      <Binder middleware={MIDDLEWARE_SETTINGS}>
        <h2 className={classNames(styles.avatarSelection__title)}>
          {t('ProfileManager.chooseAvatar')}
        </h2>
        <ul className={classNames(styles.avatarSelection__list)}>
          {avatars.map((avatar) => {
            const { contentID, URLImage, altImage = 'avatar' } = avatar || {};

            if (!URLImage) {
              return;
            }

            return (
              <li
                key={`avatar_${contentID}`}
                className={styles.avatarSelection__item}
              >
                <button
                  className={classNames(
                    styles.avatarSelection__item__avatar,
                    'globalAvatarSelection'
                  )}
                  type="button"
                  aria-label={t('ProfileManager.avatarPrefix', {
                    displayName: altImage,
                  })}
                  onClick={() => onAvatarSelection(URLImage, profileSelected)}
                >
                  <Avatar
                    aria-label={altImage}
                    image={
                      <ThumborMediaImage
                        url={URLImage}
                        urlMobile={URLImage}
                        dimensions={DIMENSIONS.PROFILE_AVATAR.extraLarge}
                      />
                    }
                    className={styles.avatarSelection__item__avatarImg}
                    isButton={false}
                  />
                </button>
              </li>
            );
          })}
        </ul>
      </Binder>
    </div>
  );
}

export default AvatarSelection;
