import { useEffect } from 'react';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { windowResizeListener } from '../../../helpers/windowListener/window-listener';
import { screenResize } from '../../../store/slices/ui';

/**
 * Init windows resize listener\
 * Used on mount only.
 */
const useWindowResizeListenerWeb = (): void => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    windowResizeListener((width) => dispatch(screenResize(width)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

const useWindowResizeListenerTv = (): void => {};

export const useWindowResizeListener = $_BUILD_RENDERMODE_CSR
  ? useWindowResizeListenerTv
  : useWindowResizeListenerWeb;
