import { applyBodyScrollbarWidth } from './helpers';
import { isClientSide } from '../window/isClientSide';

/**
 * Safely remove a class from an element
 *
 * @param {HTMLElement} elem
 */
const safeRemove =
  (elem: HTMLElement) =>
  (style: string): void => {
    if (!isClientSide()) return;

    if (elem.classList.contains(style)) {
      elem.classList.remove(style);
    }
  };

/**
 * removeBodyNoScroll
 *
 * removes noscroll css class from body, or noscroll-{suffix} if suffix is provided
 * @param {string} suffix - optional suffix to add to the class to be removed
 * @returns {function}
 */
export const removeBodyNoScroll = (suffix?: string): void => {
  safeRemove(document.body)(['noscroll', suffix].filter(Boolean).join('-'));
  applyBodyScrollbarWidth(0);
};
