import { MAX_PIXEL_RATIO } from '../constants/pixelRatio';
import { isClientSide } from '../window/isClientSide';

/**
 * Returns the device pixel ratio
 * - Returns the value of window.devicePixelRatio
 * - Returns `MAX_PIXEL_RATIO` when window.devicePixelRatio is undefined or in SSR
 */
export const getDevicePixelRatio = () =>
  isClientSide()
    ? (window.devicePixelRatio ?? MAX_PIXEL_RATIO)
    : MAX_PIXEL_RATIO;
