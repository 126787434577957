import type { StringCouple } from '../types';

/**
 * Function Helper
 * --------------------
 *
 * **StringCouple** type guard.
 *
 * @param couple The **couple** to be parsed
 *
 * @category Function
 */
export const isStringCouple = (couple: any): couple is StringCouple =>
  Array.isArray(couple) &&
  couple.every((item) => Boolean(item) && typeof item === 'string');
