import { ServicesKeys, setContext } from '@canalplus/ifc-onecore';
import { Template } from '@canalplus/sdk-hodor';
import { forwardRef, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { ButtonShapes } from '../../constants/button';
import { StrateMode } from '../../constants/strates';
import { useAppHistory } from '../../helpers/hooks/reactRouter';
import { useIsTvDevice } from '../../helpers/hooks/useIsTvDevice';
import Button from '../Button/Button';
import useLinker from './hooks/useLinker';
import { LinkerProps } from './types';

/**
 * Based on given `data`, generates one of the following:
 * - myCanal `Button` component
 * - react-router `Link` component
 * - `<a>`
 * - `<button>`
 * - or custom element with 'renderWrapper'
 *
 * with onClick, href and target values
 * @example
    <Linker
      data={{ mainOnClick: button.onClick }}
      onClick={onClose}
      title={button.displayName}
    >
      {button.displayName}
    </Linker>

    Or use render custom :

    <Linker
      data={{ mainOnClick: button.onClick }}
      onClick={onClose}
      renderWrapper={(linkerSettings: LinkerSettings, children?: ReactNode) => (
          linkerSettings.href ?
              <a href={linkerSettings.href} target={linkerSettings.target} onClick={linkerSettings.onClick}>
                {children}
              </a>
              :
              <button type="button" onClick={linkerSettings.onClick} className={styles.defaultTemplate_button}>
                {children}
              </button>
      )}
    >
      {button.displayName}
    </Linker>
*/
const Linker = forwardRef<HTMLElement, LinkerProps>(function Linker(
  props: LinkerProps,
  ref
): JSX.Element {
  const {
    altImage,
    ariaLabel,
    ariaLabelledBy,
    children,
    className,
    data,
    disabled = false,
    id,
    objKey = 'onClick',
    onClick,
    renderWrapper,
    replace,
    target = '_blank',
    title,
    ...rest
  } = props;

  const isTvDevice = useIsTvDevice();
  const { getLinkerSettings } = useLinker();
  const linkerDataDisplayTemplate = data?.mainOnClick?.displayTemplate;
  const linkerSettings = getLinkerSettings({
    data,
    objKey,
    target,
    onClick,
    replace,
  });
  const history = useAppHistory();

  /**
   * Link to linker settings onClick
   *
   * When we leave our application we send context lastUrl to oneCore
   * It allows to start application with an history used by R7ExtLib
   *
   * workflow for all linker component ex: detail and banner
   */
  const handleClickWithPepsi = (event: SyntheticEvent) => {
    const { serviceID } = data?.mainOnClick || {};
    if (serviceID === ServicesKeys.MAX_CONSENT) {
      if (isTvDevice) {
        setContext({
          context: {
            lastUrl: [history.location.pathname || '/'],
          },
        });
      }
    }

    return linkerSettings.onClick?.(event);
  };

  // case to render custom element with renderWrapper function
  if (renderWrapper) {
    return renderWrapper(linkerSettings, children);
  }

  // mostly used on detailV5 primary action button / episodeList
  if (
    linkerDataDisplayTemplate === Template.Player &&
    objKey !== StrateMode.LiveTv
  ) {
    return (
      <Button
        buttonRef={ref as React.RefObject<HTMLButtonElement>}
        id={id}
        shape={ButtonShapes.ROUND}
        className={className}
        text={title}
        isV5Style={isTvDevice}
        handler={linkerSettings.onClick}
        isDisabled={disabled}
        ariaLabel={ariaLabel}
      />
    );
  }

  // case react-router `Link` component
  if (linkerSettings.to) {
    return (
      <Link
        aria-disabled={disabled}
        aria-label={ariaLabel || altImage || title}
        aria-labelledby={ariaLabelledBy}
        className={className}
        id={id}
        onClick={linkerSettings.onClickWithoutRouting}
        ref={ref as React.RefObject<HTMLAnchorElement>}
        replace={replace}
        title={title}
        to={linkerSettings.to}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  // case HTML anchor
  if (linkerSettings.href && !linkerSettings.to) {
    return (
      <a
        aria-disabled={disabled}
        aria-label={ariaLabel || altImage || title}
        aria-labelledby={ariaLabelledBy}
        className={className}
        id={id}
        onClick={handleClickWithPepsi}
        ref={ref as React.RefObject<HTMLAnchorElement>}
        target={linkerSettings.target}
        title={title}
        {...(!disabled ? { href: linkerSettings.href } : {})}
        {...rest}
      >
        {children}
      </a>
    );
  }

  // case HTML button
  return (
    <button
      ref={ref as React.RefObject<HTMLButtonElement>}
      type="button"
      id={id}
      onClick={linkerSettings.onClick}
      className={className}
      aria-disabled={disabled}
      disabled={disabled}
    >
      {children}
    </button>
  );
});

export default Linker;
