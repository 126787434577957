import classNames from 'classnames';
import { DropdownMenuItem } from '../../Dropdown/DropdownMenuItem';
import type { HeaderUserDropdownListProps } from './HeaderUserDropdownList.types';
import HeaderUserDropdownListItem from './HeaderUserDropdownListItem';

/**
 * Component to display a list of items in a dropdown menu, in which each item is a `HeaderUserDropdownListItem`.
 *
 * @param  props.items The items to display in the list
 * @returns The list of items - each item being a `HeaderUserDropdownListItem`
 * @example
 *  <HeaderUserDropdownList items={items} />
 *  <HeaderUserDropdownList items={items} title="My List" />
 */
export function HeaderUserDropdownList({
  items,
  title,
}: HeaderUserDropdownListProps): JSX.Element {
  const resetUlStyles = 'list-none m-dt-spacing-none px-dt-spacing-none';
  return (
    <ul
      role="menu"
      className={classNames(resetUlStyles, 'space-y-dt-spacing-50')}
    >
      {title && (
        <li
          role="none"
          className={classNames(
            'px-dt-spacing-200 text-dt-theme-text-list-row-list-title',
            'font-dt-font-family-system font-dt-font-weight-semibold',
            'text-dt-font-size-22 leading-dt-font-line-height-26'
          )}
        >
          {title}
        </li>
      )}
      {items?.map((item) => (
        <li role="none" key={`navlist-dropdownmenu-${item.label}`}>
          <DropdownMenuItem
            label={item.label}
            renderComponent={({ isActive, itemProps }) => (
              <HeaderUserDropdownListItem
                isFocused={isActive}
                {...item}
                {...itemProps}
              />
            )}
          />
        </li>
      ))}
    </ul>
  );
}
