import { isClientSide, setCookie } from '@canalplus/mycanal-commons';
import { requestPage } from '@canalplus/sdk-hodor';
import type { ApiV2StartupNotificationsPage } from '@dce-front/hodor-types/api/v2/me/startup_notifications/definitions';
import Cookies from 'js-cookie';
import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { CookieKey } from '../../../constants/cookie';
import { getNotificationExpires } from '../../../helpers/date/date-helper';
import { useAppDispatch } from '../../../helpers/hooks/useAppDispatch';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import {
  getFeatureToggleNotifications,
  hodorSdkConfigSelector,
  notificationsUrlSelector,
  offerZoneSelector,
} from '../../../store/slices/application-selectors';
import { setUserNotification } from '../../../store/slices/user';
import {
  authenticatedSelector,
  cgaNumberSelector,
} from '../../../store/slices/user-selectors';
import type { UserNotification } from '../../../store/slices/user-type';
import { getNotificationsUrl } from './helpers/getNotificationsUrl';

export const useStartupNotification = (): void => {
  const [isNotificationRetrieved, setIsNotificationRetrieved] = useReducer(
    () => true,
    false
  );
  const dispatch = useAppDispatch();

  const authenticated = useSelector(authenticatedSelector);
  const featNotifications = useInvariantSelector(getFeatureToggleNotifications);
  const cgaNumber = useSelector(cgaNumberSelector);
  const offerZone = useInvariantSelector(offerZoneSelector);
  const path = useInvariantSelector(notificationsUrlSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);

  useEffect(() => {
    (async function performStartupNotification() {
      if (
        !isNotificationRetrieved &&
        authenticated &&
        featNotifications &&
        isClientSide() &&
        hodorSdkConfig &&
        path
      ) {
        const cacheNotification = Cookies.get(CookieKey.Notification);
        const parsedNotification: UserNotification = cacheNotification
          ? JSON.parse(cacheNotification)
          : undefined;

        if (parsedNotification) {
          dispatch(setUserNotification(parsedNotification));
        } else {
          const notificationsPage = (await requestPage(hodorSdkConfig, {
            url: getNotificationsUrl({ cgaNumber, offerZone, path }),
          })) as ApiV2StartupNotificationsPage;

          // The user can only have one notification at a time
          // For now we only take the first notification sent by hodor, leaving it the handler of the priority
          const mainNotification = notificationsPage.startupNotifications?.[0];

          if (mainNotification) {
            dispatch(setUserNotification(mainNotification));

            const expires = mainNotification.refreshInterval
              ? getNotificationExpires(mainNotification.refreshInterval)
              : undefined;

            setCookie(CookieKey.Notification, mainNotification, {
              sameSite: 'lax',
              ...(expires && { expires }),
            });
          }
        }

        setIsNotificationRetrieved();
      }
    })();
  }, [
    authenticated,
    cgaNumber,
    dispatch,
    featNotifications,
    hodorSdkConfig,
    isNotificationRetrieved,
    offerZone,
    path,
  ]);
};
