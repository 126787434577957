import { cva } from 'class-variance-authority';

export const listCVA = cva(
  [
    'items-center justify-between flex flex-row border-solid box-border space-x-dt-spacing-200',
    'font-dt-font-family-system font-dt-font-weight-regular',
  ],
  {
    variants: {
      device: {
        tv: [
          'rounded-dt-radius-xlarge',
          'text-dt-font-size-30 leading-dt-font-line-height-36',
          'px-dt-spacing-300',
          'py-dt-spacing-125',
          'tv-focus-self:scale-tv-focus',
          'tv-focus-self:bg-dt-theme-tv-surface-list-list-focus',
          'border-dt-border-regular',
          'tv-focus-self:border-dt-theme-tv-surface-list-list-focus',
        ],
        web: [
          'rounded-dt-radius-medium',
          'text-dt-font-size-18 leading-dt-font-line-height-22',
          'px-dt-spacing-200',
          'py-dt-spacing-none',
          'border-dt-border-thin border-dt-theme-border-list-row-list-row',
        ],
      },
      isDisabled: {
        false: 'cursor-pointer',
      },
    },
    compoundVariants: [
      {
        device: 'tv',
        isDisabled: true,
        className: 'bg-transparent border-dt-theme-tv-border-list-list',
      },
      {
        device: 'tv',
        isDisabled: false,
        className:
          'bg-dt-theme-tv-surface-list-list border-dt-theme-tv-surface-list-list',
      },
      {
        device: 'web',
        isDisabled: false,
        className: 'bg-dt-theme-surface-list-row-list-row',
      },
      {
        device: 'web',
        isDisabled: true,
        className: 'bg-dt-theme-surface-list-row-list-row-disabled',
      },
    ],
  }
);

export const listLeftContentCVA = cva(
  'flex items-center overflow-x-clip space-x-dt-spacing-200',
  {
    variants: {
      isTv: {
        true: 'py-dt-spacing-50',
        false: undefined,
      },
      hasLeftIcon: {
        true: undefined,
        false: undefined,
      },
    },
    compoundVariants: [
      {
        isTv: false,
        hasLeftIcon: true,
        className: 'py-dt-spacing-100',
      },
      {
        isTv: false,
        hasLeftIcon: false,
        className: 'py-dt-spacing-175',
      },
    ],
  }
);

export const listLeftElementContainerCVA = cva(['leading-5'], {
  variants: {
    device: { tv: 'flex', web: 'flex items-start' },
    layout: {
      inline: 'flex-row',
      stacked: 'flex-col',
    },
  },
  compoundVariants: [
    {
      device: 'tv',
      layout: 'inline',
      className: 'space-x-dt-spacing-200',
    },
    {
      device: 'tv',
      layout: 'stacked',
      className: 'space-y-dt-spacing-100',
    },
    {
      device: 'web',
      layout: 'inline',
      className:
        'items-baseline space-y-dt-spacing-none space-x-dt-spacing-100',
    },
    {
      device: 'web',
      layout: 'stacked',
      className: 'space-y-dt-spacing-100 space-x-dt-spacing-none',
    },
  ],
});

export const listTitleCVA = cva(['line-clamp-2 text-start'], {
  variants: {
    device: {
      tv: [
        'text-dt-theme-tv-text-list-list-row-title-default tv-focus-parent:text-dt-theme-tv-text-list-list-row-title-focus',
        'text-dt-font-size-30 leading-dt-font-line-height-36',
      ],
      web: 'text-dt-font-size-18 leading-dt-font-line-height-22',
    },
    isDisabled: {
      false: undefined,
      true: undefined,
    },
  },
  compoundVariants: [
    {
      device: 'web',
      isDisabled: false,
      className: 'text-dt-theme-text-list-row-list-title',
    },
    {
      device: 'web',
      isDisabled: true,
      className: 'text-dt-theme-text-list-row-list-row-title-disabled',
    },
  ],
});

export const listTextCVA = cva(['text-start'], {
  variants: {
    device: {
      tv: [
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-subtitle-focus text-dt-theme-tv-text-list-list-row-subtitle-default',
        'text-dt-font-size-30 leading-dt-font-line-height-36',
      ],
      web: 'text-dt-font-size-16 leading-dt-font-line-height-20',
    },
    isDisabled: {
      false: undefined,
      true: undefined,
    },
  },
  compoundVariants: [
    {
      device: 'web',
      isDisabled: false,
      className: 'text-dt-theme-text-list-row-list-row-subtitle',
    },
    {
      device: 'web',
      isDisabled: true,
      className: 'text-dt-theme-text-list-row-list-row-subtitle-disabled',
    },
  ],
});

export const listLineThroughLabelCVA = cva(['text-start'], {
  variants: {
    device: {
      tv: [
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-promotion-focus text-dt-theme-tv-text-list-list-row-promotion-default',
        'text-dt-font-size-30 leading-dt-font-line-height-36',
      ],
      web: 'text-dt-font-size-16 leading-dt-font-line-height-20',
    },
    isDisabled: {
      false: undefined,
      true: undefined,
    },
  },
  compoundVariants: [
    {
      device: 'web',
      isDisabled: false,
      className: 'text-dt-theme-text-list-row-list-row-promotion',
    },
    {
      device: 'web',
      isDisabled: true,
      className: 'text-dt-theme-text-list-row-list-row-promotion-disabled',
    },
  ],
});

export const listLabelCVA = cva(undefined, {
  variants: {
    device: {
      tv: [
        'tv-focus-parent:text-dt-theme-tv-text-list-list-row-label-focus',
        'text-dt-theme-tv-text-list-list-row-label-default',
      ],
      web: 'text-dt-font-size-16 leading-dt-font-line-height-20',
    },
    hasLineThrough: { true: undefined, false: undefined },
    isDisabled: { true: undefined, false: undefined },
  },
  compoundVariants: [
    {
      device: 'web',
      hasLineThrough: true,
      isDisabled: false,
      className: 'text-dt-theme-text-list-row-list-row-price',
    },
    {
      device: 'web',
      hasLineThrough: true,
      isDisabled: true,
      className: 'text-dt-theme-text-list-row-list-row-price-disabled',
    },
    {
      device: 'web',
      hasLineThrough: false,
      isDisabled: false,
      className: 'text-dt-theme-text-list-row-list-row-label',
    },
    {
      device: 'web',
      hasLineThrough: false,
      isDisabled: true,
      className: 'text-dt-theme-text-list-row-list-row-label-disabled',
    },
  ],
});

export const listRightElementContainerCVA = cva(
  ['flex items-center relative'],
  {
    variants: {
      device: {
        /**
         * On TV, the List height doesn't take the icon size into account,
         * hence the `h-0` in the container so it doesn't impact the List height,
         * and `h-50` set to the absolute positioned child container to recover the height.
         */
        tv: 'w-min min-w-50 h-0',
        web: 'w-min min-w-24 h-24',
      },
    },
  }
);

export const listRightElementPositionCVA = cva(undefined, {
  variants: {
    device: {
      tv: 'absolute w-full h-50',
      web: 'w-full h-full',
    },
  },
});

export const listRightIconCVA = cva(undefined, {
  variants: {
    device: {
      tv: 'tv-focus-parent:fill-dt-theme-tv-icon-list-icon-focus fill-dt-theme-tv-icon-list-icon',
      web: undefined,
    },
    hasLineThrough: { true: undefined, false: undefined },
    isDisabled: { true: undefined, false: undefined },
  },
  compoundVariants: [
    {
      device: 'web',
      hasLineThrough: true,
      isDisabled: false,
      className: 'fill-dt-theme-icon-list-row-chevron-price',
    },
    {
      device: 'web',
      hasLineThrough: false,
      isDisabled: false,
      className: 'fill-dt-theme-icon-list-row-chevron',
    },
    {
      device: 'web',
      hasLineThrough: true,
      isDisabled: true,
      className: 'fill-dt-theme-icon-list-row-chevron-price-disabled',
    },
    {
      device: 'web',
      hasLineThrough: false,
      isDisabled: true,
      className: 'fill-dt-theme-icon-list-row-chevron-disabled',
    },
  ],
});
