import { IButtonStrate, ISectionsStrate } from '@canalplus/mycanal-sdk';
import { ContentType } from '@canalplus/sdk-hodor';
import { ApiV2PageSectionsList } from '@dce-front/hodor-types/api/v2/page/dtos/display_templates/sections_list';
import { useQuery } from '@tanstack/react-query';
import { useSelector, useStore } from 'react-redux';
import {
  DEFAULT_CACHE_TIME,
  DEFAULT_STALE_TIME,
} from '../../../constants/reactQuery';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { getUserMenuExtended } from '../../../services/userMenuExtended/getUserMenuExtended';
import { getUserMenuLanguages } from '../../../services/userMenuExtended/getUserMenuLanguages';
import {
  hodorSdkConfigSelector,
  userMenuSelector,
} from '../../../store/slices/application-selectors';
import { IState } from '../../../store/types/State-type';
import { MockApiV2UserMenuLanguages } from '../../../typings/hodor';

const prefixQueryKey = 'useUserMenuExtended';

/**
 * Hook to call getUserMenuExtended and getUserMenuLanguages and get userMenuExtended, userMenuLanguages and userMenuExtendedNonProfileStrates
 * It use useQuery with a cache
 */
export function useUserMenuExtended(): {
  userMenuExtended?: ApiV2PageSectionsList;
  userMenuLanguages?: MockApiV2UserMenuLanguages;
  userMenuExtendedNonProfileStrates: (ISectionsStrate | IButtonStrate)[];
} {
  const userMenu = useInvariantSelector(userMenuSelector);
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);
  const state = useStore<IState>().getState();

  const { data } = useQuery({
    queryKey: [prefixQueryKey, hodorSdkConfig], // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: async () => {
      if (!hodorSdkConfig) return;

      const [userMenuExtended, userMenuLanguages] = await Promise.all([
        // Call to user menu extended returned by authenticate.json
        getUserMenuExtended({
          sdkConfig: hodorSdkConfig,
          state,
          userMenu,
        }),
        // get languages
        // @todo maybe we can add condition to get language on for suisse ?
        getUserMenuLanguages(hodorSdkConfig, userMenu),
      ]);

      // Non Profile Strates
      let userMenuExtendedNonProfileStrates: (
        | ISectionsStrate
        | IButtonStrate
      )[] = [];
      if (userMenuExtended) {
        userMenuExtendedNonProfileStrates = userMenuExtended.strates?.filter(
          (strate) => strate.type !== ContentType.Profiles
        ) as (ISectionsStrate | IButtonStrate)[];
      }

      return {
        userMenuExtended,
        userMenuLanguages,
        userMenuExtendedNonProfileStrates,
      };
    },
    gcTime: DEFAULT_CACHE_TIME,
    staleTime: DEFAULT_STALE_TIME,
    enabled: Boolean(hodorSdkConfig),
  });

  return {
    userMenuExtendedNonProfileStrates:
      data?.userMenuExtendedNonProfileStrates || [],
    userMenuExtended: data?.userMenuExtended,
    userMenuLanguages: data?.userMenuLanguages,
  };
}
