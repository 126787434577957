import { PureComponent } from 'react';

/**
 * Create Consumer
 *
 * This component allows to connect to i18n the components that need it (like 'connect' in Redux)
 * It's a react context "Consumer", so it must be defined inside I18n "Provider" to work
 * Recommendation: all WrappedComponents must be PureComponent to prevent side effects (rerender)
 */
const createConsumer = (Consumer) => (WrappedComponent) => {
  // eslint-disable-next-line react/prefer-stateless-function
  class ConnectedComponent extends PureComponent {
    render() {
      return <Consumer>{({ t }) => <WrappedComponent {...this.props} t={t} />}</Consumer>;
    }
  }

  ConnectedComponent.displayName = `I18n(${WrappedComponent.displayName || WrappedComponent.name || 'Unknown'})`;

  return ConnectedComponent;
};

createConsumer.displayName = 'createConsumer';

export default createConsumer;
