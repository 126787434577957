import classNames from 'classnames';
import { forwardRef } from 'react';
import { useDiveContext } from '../../context';
import type { AvatarProps } from './Avatar.types';
import { AvatarVariant } from './Avatar.types';
import { AvatarImage } from './AvatarImage/AvatarImage';
import type { AvatarImageProps } from './AvatarImage/AvatarImage.types';

export type Ref = HTMLButtonElement | HTMLDivElement;

/**
 * Displays an Avatar image, with an optional label and contextual icons.
 *
 * @example
 * <Avatar
 *   isEdit
 *   avatarImg={{
 *     url: 'https://image.png',
 *     alt: 'Avatar alt label',
 *   }}
 * />
 */
export const Avatar = forwardRef<Ref, AvatarProps>(
  (
    {
      onClick,
      id,
      image,
      label,
      variant = AvatarVariant.Default,
      isKids = false,
      isEdit = false,
      isButton = true,
      disabled = false,
      className,
      showPlaceholderAnim,
      'data-testid': dataTestId,
      ...a11y
    },
    forwardedRef
  ): JSX.Element => {
    const { isTv } = useDiveContext();

    const resetStyles = classNames(
      'border-dt-border-none bg-transparent p-dt-spacing-none max-w-full max-h-full shrink-0',
      {
        'focus:outline-none': isTv,
        'aspect-w-1 aspect-h-1': !label,
      }
    );

    const avatarImageProps = {
      variant,
      isKids,
      isEdit,
      label,
      isTv,
      image,
      showPlaceholderAnim,
    } satisfies AvatarImageProps;

    if (isButton) {
      return (
        <button
          ref={forwardedRef as React.Ref<HTMLButtonElement>}
          onClick={onClick}
          className={classNames(
            resetStyles,
            'enabled:cursor-pointer disabled:cursor-not-allowed',
            className
          )}
          id={id}
          type="button"
          data-testid={dataTestId}
          disabled={disabled}
          aria-disabled={disabled}
          {...a11y}
        >
          <AvatarImage {...avatarImageProps} />
        </button>
      );
    }

    return (
      <div
        ref={forwardedRef as React.Ref<HTMLDivElement>}
        className={classNames(resetStyles, className)}
        id={id}
        data-testid={dataTestId}
        {...a11y}
      >
        <AvatarImage {...avatarImageProps} />
      </div>
    );
  }
);

Avatar.displayName = 'Avatar';
