export enum KEYCODE {
  ARROW_DOWN = 40,
  ARROW_LEFT = 37,
  ARROW_RIGHT = 39,
  ARROW_UP = 38,
  BACKSPACE = 8,
  ENTER = 13,
  ESCAPE = 27,
  FORWARD = 119,
  REWIND = 118,
  SHIFT = 16,
  TAB = 9,
}

export const ARROW_KEYCODES = [
  KEYCODE.ARROW_DOWN,
  KEYCODE.ARROW_LEFT,
  KEYCODE.ARROW_RIGHT,
  KEYCODE.ARROW_UP,
];

export const AVAILABLE_A11Y_KEYCODES = [...ARROW_KEYCODES, KEYCODE.TAB];
