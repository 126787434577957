import IconClose from '../../assets/svg/close.svg';
import { ModalTypes } from '../../constants/modalTypes';
import type { closeModal } from '../../store/slices/modal';
import type { UserSettingsState } from '../../store/slices/user-type';
import type { AppHistory } from '../../typings/routing';
import LoadableStoreRedirection from '../StoreRedirection/LoadableStoreRedirection';
import LoadableD2GConfirmationDeletionModal from './DownloadToGoModal/D2GConfirmationDeletionModal/LoadableD2GConfirmationDeletionModal';
import LoadableD2GDownloadModal from './DownloadToGoModal/D2GDownloadModal/LoadableD2GDownloadModal';
import LoadableD2GEpisodeListdModal from './DownloadToGoModal/D2GEpisodeList/LoadableD2GEpisodeListModal';
import LoadableD2GInformationModal from './DownloadToGoModal/D2GInformationModal/LoadableD2GInformationModal';
import LoadableD2GQualityModal from './DownloadToGoModal/D2GQualityModal/loadableD2GDownloadModal';
import LoadableDownloadToGoMacOsModalConnected from './DownloadToGoModal/DownloadToGoMacOsModal/LoadableDownloadToGoMacOsModalConnected';
import LoadableDownloadToGoModalConnected from './DownloadToGoModal/LoadableDownloadToGoModalConnected';
import LoadableExternalServiceModal from './ExternalServiceModal/LoadableExternalServiceModal';
import LoadableModal from './LoadableModal';
import LoadableMobileAppDownloadModal from './MobileAppDownloadModal/LoadableMobileAppDownloadModal';
import LoadableMoodModal from './MoodModal/LoadableMoodModal';
import LoadableStartOverModal from './StartOverModal/LoadableStartOverModal';
import LoadableUserExitsSettingsModal from './UserSettingsModal/LoadableUserExitsSettingsModal';
import LoadableUserSettingsModal from './UserSettingsModal/LoadableUserSettingsModal';
import LoadableZonesListModal from './ZonesListModal/LoadableZonesListModal';
import { useCloseConfirm } from './useCloseConfirm';

export type ModalContainerProps = {
  children?: React.ReactNode;
  afterClosing?: () => void;
  closeAction: typeof closeModal;
  isMobile: boolean;
  isOpen: boolean;
  modalType: ModalTypes;
  modalProps?: any;
  history: AppHistory;
  clickedElement?: HTMLElement | null;
};

/**
 * This container should be present in a page only one time.
 * It permits to display a modal with content
 * after the dispatch of an Redux action `OPEN_MODAL`.
 *
 * @param isOpen             toggles modal's visibility
 * @param closeAction        function triggered when clicking the close button
 * @param afterClosing       function triggered after clicking the close button
 * @param modalType          type of pfv, live, hapi, epg
 * @param modalProps         options passed to modal
 * @param children           component(s)/dom element to render inside modal
 */
function ModalContainer({
  afterClosing,
  closeAction,
  isMobile,
  isOpen,
  modalType,
  modalProps,
  children,
  history,
  clickedElement,
}: ModalContainerProps): JSX.Element | null {
  const closeConfirm = useCloseConfirm();

  if (!isOpen) {
    return null;
  }

  const onClosing = async (
    modalTypeToClose: ModalTypes = modalType,
    settings?: UserSettingsState
  ) => {
    const isSettings = !!settings?.nextActionBeforeSaving;

    if (isSettings) {
      await closeConfirm(settings, history);
    } else {
      closeAction(modalTypeToClose);
    }

    // Get back focused element for accessibility
    if (clickedElement) {
      clickedElement.focus();
    }

    if (afterClosing) {
      afterClosing();
    }
  };

  const closeButtonIcon = <IconClose />;

  switch (modalType) {
    case ModalTypes.MOBILE_APP_DOWNLOAD:
      return (
        <LoadableMobileAppDownloadModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.DOWNLOAD_TO_GO_MODAL:
      return isMobile ? (
        <LoadableModal
          closeButtonIcon={closeButtonIcon}
          modalType={modalType}
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        >
          <LoadableStoreRedirection />
        </LoadableModal>
      ) : (
        <LoadableDownloadToGoModalConnected
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
          history={history}
        />
      );

    case ModalTypes.DOWNLOAD_TO_GO_DELETION_MODAL:
      return (
        <LoadableD2GConfirmationDeletionModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={onClosing}
          {...modalProps}
        />
      );

    case ModalTypes.DOWNLOAD_TO_GO_DOWNLOAD_MODAL:
      return (
        <LoadableD2GDownloadModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={onClosing}
          {...modalProps}
        />
      );

    case ModalTypes.DOWNLOAD_TO_GO_INFORMATION_MODAL:
      return (
        <LoadableD2GInformationModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={onClosing}
          {...modalProps}
        />
      );

    case ModalTypes.DOWNLOAD_TO_GO_QUALITY_MODAL:
      return (
        <LoadableD2GQualityModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={onClosing}
          {...modalProps}
        />
      );

    case ModalTypes.DOWNLOAD_TO_GO_EPISODE_LIST_MODAL:
      return (
        <LoadableD2GEpisodeListdModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={onClosing}
          {...modalProps}
        />
      );

    case ModalTypes.USER_SETTINGS_MODAL:
      return (
        <LoadableUserSettingsModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.USER_EXITS_SETTINGS_MODAL:
      return (
        <LoadableUserExitsSettingsModal
          isOpen={isOpen}
          closeAction={onClosing}
          {...modalProps}
        />
      );

    case ModalTypes.MOOD_MODAL:
      return (
        <LoadableMoodModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
          history={history}
        />
      );

    case ModalTypes.ZONES_LIST_MODAL:
      return (
        <LoadableZonesListModal
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.GENERIC_MODAL:
      return (
        <LoadableModal
          closeAction={() => onClosing(modalType)}
          closeButtonIcon={closeButtonIcon}
          isOpen={isOpen}
          {...modalProps}
        >
          {children}
        </LoadableModal>
      );

    case ModalTypes.EXTERNAL_SERVICE_STREAM_MODAL:
      return (
        <LoadableExternalServiceModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
        />
      );

    case ModalTypes.EXTERNAL_SERVICE_DOWNLOAD_MODAL:
      return (
        <LoadableExternalServiceModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          isDownload
          {...modalProps}
        />
      );

    case ModalTypes.STARTOVER_MODAL:
      return (
        <LoadableStartOverModal
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
          history={history}
        />
      );

    case ModalTypes.DOWNLOAD_TO_GO_MAC_OS_MODAL:
      return (
        <LoadableDownloadToGoMacOsModalConnected
          isOpen={isOpen}
          closeAction={() => onClosing(modalType)}
          {...modalProps}
          history={history}
        />
      );

    default:
      return null;
  }
}

export default ModalContainer;
