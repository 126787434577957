import { PureComponent } from 'react';
import Polyglot from 'node-polyglot';

/**
 * Create Provider
 *
 * This component allows to connect to the i18n Provider the components that need it (like 'connect' in Redux)
 * It's a react context "Provider", it must be defined in the project tree’s highest parent component
 */
const createProvider = (Provider) => {
  let polyglot;

  return (getLocalPhrases) => (WrappedComponent) => {
    class ConnectedComponent extends PureComponent {
      constructor(props) {
        super(props);
        const { locale } = this.props;
        const localPhrases = getLocalPhrases(locale) || {};
        polyglot =
          polyglot && polyglot.locale() === locale ? polyglot : new Polyglot({ phrases: localPhrases, locale });

        this.state = {
          t: (ref, args = {}) => polyglot.t(ref, args),
        };
      }

      render() {
        return (
          <Provider value={{ t: this.state.t }}>
            <WrappedComponent {...this.props} />
          </Provider>
        );
      }
    }

    ConnectedComponent.displayName = `I18n.Provider(${
      WrappedComponent.displayName || WrappedComponent.name || 'Unknown'
    })`;

    return ConnectedComponent;
  };
};

createProvider.displayName = 'createProvider';

export default createProvider;
