import { isInitialized as isIfcOneCoreInitialized } from '@canalplus/ifc';
import { getSystem } from '@canalplus/ifc-onecore';
import {
  getPlatformFromOneCorePlatform,
  getPlatformGroupFromPlatform,
  OfferLocation,
  OneCorePlatform,
  PlatformGroup,
} from '@canalplus/sdk-core';
import { injectScript } from '../scripts/scripts-helper';
import { canUseNewrelic } from '../window/window-helper';

const wait = (ms: number): Promise<any> =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Wait for global object `newrelic` to exist with a timeout
 */
const waitNewrelic = async (tryCount: number = 0): Promise<void> => {
  if (!canUseNewrelic()) {
    await wait(1000 * tryCount); // wait 1 second * tryCount
    await waitNewrelic(tryCount + 1);
  }
};

const PLATFORM_GROUP_DISABLED_NEWRELIC: PlatformGroup[] = [
  PlatformGroup.Orange,
  PlatformGroup.SFR,
  PlatformGroup.PlayStation,
];

export const injectNewrelicScriptCSR = ({
  offerLocation,
  oneCoreTarget,
}: {
  offerLocation?: OfferLocation;
  oneCoreTarget: OneCorePlatform;
}) => {
  if ($_BUILD_ESVERSION === 'es5') {
    return;
  }

  if (process.env.NODE_ENV === 'development') {
    return;
  }

  const platformGroup = getPlatformGroupFromPlatform(
    getPlatformFromOneCorePlatform(oneCoreTarget)
  );
  // newrelic should be disabled for SFR, Orange and Playstation
  const isNewRelicDisabled =
    PLATFORM_GROUP_DISABLED_NEWRELIC.includes(platformGroup);

  if (isNewRelicDisabled) {
    return;
  }

  switch (offerLocation) {
    case 'pl':
      injectScript('mycanal-nrPL.js');
      break;
    case 'it':
      injectScript('tim-nr.js');
      break;
    default:
      injectScript('mycanal-nr.js');
      break;
  }
};

/**
 * Retrieve platform properties from one-core api (if available) to send to newrelic
 */
const newrelicGetPlatformProperties = async (): Promise<
  { name: string; value: string }[]
> => {
  if (isIfcOneCoreInitialized()) {
    const system = await getSystem();

    return [
      { name: 'target', value: system.target },
      { name: 'targetGroup', value: system.target_group || 'unknown' },
      { name: 'platform', value: system.platform || 'unknown' },
      {
        name: 'manufacturerName',
        value: system.manufacturer_name || 'unknown',
      },
      {
        name: 'manufacturerVersion',
        value: system.manufacturer_version || 'unknown',
      },
    ];
  } else {
    return Promise.resolve([{ name: 'target', value: 'web' }]);
  }
};

/**
 * Send newrelic global information
 * @param  {string}  webappVersion - webapplication version
 */
export const newrelicSendGlobalInfo = async (
  webappVersion: string
): Promise<void> => {
  await waitNewrelic();

  const newrelic = window['newrelic'];
  // add the version of the OS
  const osVersionResult = navigator.userAgent.match(/\((.+?)\)/);
  const userOsVersion =
    osVersionResult && osVersionResult.length >= 1
      ? osVersionResult[1]
      : 'Unknown';
  newrelic.setCustomAttribute('userOsVersion', userOsVersion);

  if (typeof (global as any).OnePlayer !== 'undefined') {
    newrelic.setCustomAttribute(
      'playerVersion',
      (global as any).OnePlayer.__version__
    );
  }

  // Add the webapp version
  newrelic.setCustomAttribute('webappVersion', webappVersion);
  newrelic.addRelease('webappVersion', webappVersion);

  // Add device specific information
  try {
    const properties = await newrelicGetPlatformProperties();

    properties.forEach(({ name, value }) => {
      newrelic.setCustomAttribute(name, value);
    });
  } catch (error) {
    console.error(error);
  }
};

/**
 * Send anonymous user information
 * @param  {object}  userData - userData object
 * @param  {string}  pageType - Page type
 * @param {string}   pageName - Page name
 * @param {string}   offerLocation - offerLocation
 */
export const newrelicSendUserInfo = async (
  userData: any,
  pageType: string,
  pageName: string,
  offerLocation: string
): Promise<void> => {
  await waitNewrelic();
  // Add anonymous userInfo
  const micros = userData.microEligibility;

  const userStatus =
    micros.indexOf('O_ABO') >= 0
      ? 'Abonne'
      : userData.subscriberId
        ? 'Prospect'
        : 'Inconnu';

  const newrelic = window['newrelic'];

  newrelic.setCustomAttribute('userStatus', userStatus);
  newrelic.setCustomAttribute('userId', userData.subscriberId);
  newrelic.setCustomAttribute('userAuthLevel', userData.auth_level);
  newrelic.setCustomAttribute(
    'userActivationStatus',
    userData.activation_status
  );
  // We are temporarily removing information about Micros from newrelic metrics \
  // to confirm the hypothesis that this is the cause of the unexplained volume increase since March 2021.
  // newrelic.setCustomAttribute('userMicros', userData.microEligibility);
  newrelic.setCustomAttribute('app_zone', offerLocation);
  newrelic.setPageViewName(pageName);
  if (pageType) {
    newrelic.setCustomAttribute('pageType', pageType);
  }
};

export const newrelicSendPerformanceEntries = (
  entries: PerformanceEntryList
): void => {
  waitNewrelic()
    .then(() => {
      const newrelic = window['newrelic'];

      entries.forEach((entry) => {
        newrelic.addToTrace({
          name: entry.name,
          type: entry.entryType,
          start: entry.startTime,
          end:
            entry.entryType === 'measure'
              ? entry.startTime + entry.duration
              : undefined,
        });
      });
    })
    // We don't care if its not sent
    .catch(() => {});
};
