import type { FormattedSocialLink } from './types';

export const defaultSocialLinks: FormattedSocialLink[] = [
  {
    name: 'facebook',
    displayName: 'Facebook',
    link: 'https://www.facebook.com/CANALfrance',
  },
  {
    name: 'twitter',
    displayName: 'Twitter',
    link: 'https://twitter.com/CANAL_France',
  },
  {
    name: 'dailymotion',
    displayName: 'Dailymotion',
    link: 'https://www.dailymotion.com/CANALPLUS',
  },
  {
    name: 'youtube',
    displayName: 'Youtube',
    link: 'https://www.youtube.com/user/canalplusfrance',
  },
  {
    name: 'linkedin',
    displayName: 'LinkedIn',
    link: 'https://www.linkedin.com/company/canal-',
  },
];
