import { getEncodedQueryString } from '@canalplus/mycanal-commons';
import type { PassSdkConfig } from '../config/types';
import { paths } from '../constants';

export type GetLogoutUrlParameters = {
  /** Redirect Url to redirect once the disconnection is done */
  targetUrl: string;

  /** Zone for which the disconnection is desired. If this parameter is not provided, the disconnection is only done on the Provider */
  zone?: string;
};

/**
 * Get the Pass logout URL
 * @see https://canal-plus.atlassian.net/wiki/spaces/PASS/pages/161844257/Logout
 */
export const getLogoutUrl = (
  { baseUrl: { oauth: oauthBaseUrl } }: PassSdkConfig,
  { targetUrl, zone }: GetLogoutUrlParameters
): string => {
  const url = `${oauthBaseUrl}${paths.logout}`;

  const encodedQueryString = getEncodedQueryString({
    targetUrl,
    ...(zone && { zone }),
  });

  return `${url}${encodedQueryString}`;
};
